import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from "react-js-loader";
import Modal from 'react-modal';
import expand from './../../../assets/images/expand.svg';
import calendar from './../../../assets/images/calendar.png';
import topArrow from './../../../assets/images/export.svg';
import { COLORS, CancerSiteColors, HospitalColor } from '../../../utils/constants'
import { PieChart, Pie, Cell, Tooltip, Legend, BarChart, LabelList, Bar, CartesianGrid, XAxis, YAxis, ResponsiveContainer } from "recharts";
const HRR = (props) => {
    const { allGraphData, top10totalCount, filterGraphs, pdlFilterVal,
        filterVal, top20CitiesData, hospitalNameData, setHospitalNameData, downloadLoading,
        hospitalName, setHospitalName, AllGraphsList, excelDownload, pdlCloneFilterVal } = props
    const [citiesOpen, setCitiesOpen] = useState(false);
    const [hospitalOpen, sethospitalOpen] = useState(false);
    const [hospitalShow, sethospitalShow] = useState(false);
    const [bioMarkerOpen, setbioMarkerOpen] = useState(false);
    const navigate = useNavigate();
    const RADIAN = Math.PI / 180;
    const calculateLabelColor = (backgroundColor) => {
        if (backgroundColor.startsWith('#')) {
          const hex = backgroundColor.replace('#', '');
          const rgb = hex.match(/.{1,2}/g).map(val => parseInt(val, 16));

          const luminance = (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255;
      
          return luminance > 0.5 ? '#000000' : '#ffffff'; // Light background: use dark label color, Dark background: use light label color
        }
      
        if (backgroundColor.startsWith('rgb')) {
          const rgb = backgroundColor.match(/\d+/g).map(val => parseInt(val, 10));
          const luminance = (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255;
          return luminance > 0.5 ? '#000000' : '#ffffff';
        }
      
        return '#000000';
      };
    const TmbMsiCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (top10totalCount)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };
    const TMBMSICustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, payload }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x - 8} y={y}
                fill={calculateLabelColor(payload?.fill)}
                fontSize={10}
                // textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central">
                {(percent * 100).toFixed(0) > 1 ? `${(percent * 100).toFixed(0)}%` : null}
                {/* {payload?.payload?.value} */}
            </text>

        );
    };

    function citiesModal() {
        setCitiesOpen(true);
    }
    function hospitalModal() {
        sethospitalOpen(true);
    }

    function hospitalLocation(params) {
        const hospital = params?.hospitalNameResList?.filter((item) => {
            if ((item.value !== 0)) {
                return item
            }
        })
        setHospitalNameData(hospital)
        sethospitalShow(true);
        setHospitalName(params?.name)
    }
    function citiesCloseModal() {
        setCitiesOpen(false);
    }
    function hospitalCloseModal() {
        sethospitalOpen(false);
    }

    function commafy(num) {
        if (num) {
            var str = num.toString().split('.');
            if (str[0].length >= 3) {
                str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
            }
            if (str[1] && str[1].length >= 3) {
                str[1] = str[1].replace(/(\d{3})/g, '$1 ');
            }
            return str.join('.');
        }
    }

    const bioMarkerdata = [
        {
            name: 'Page A',
            uv: 1000,
            pv: 2400,
            amt: 2400,
            as: 234,
            tt: 234
        },
        {
            name: 'Page B',
            uv: 2000,
            pv: 1398,
            amt: 2210,
            tt: 234

        },
        {
            name: 'Page C',
            uv: 1200,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Page D',
            uv: 2080,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page E',
            uv: 1190,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page F',
            uv: 2690,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Page G',
            uv: 1490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Page H',
            uv: 2380,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page I',
            uv: 1090,
            pv: 4500,
            amt: 2181,
        },
        {
            name: 'Page J',
            uv: 2290,
            pv: 3800,
            amt: 2100,
        },
        {
            name: 'Page K',
            uv: 2290,
            pv: 4300,
            amt: 2300,
        },
        {
            name: 'Page L',
            uv: 1180,
            pv: 3938,
            amt: 2400,
        },
        {
            name: 'Page M',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page N',
            uv: 2390,
            pv: 3100,
            amt: 2000,
        },
        {
            name: 'Page O',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];

    // Get all unique keys from all objects in the array
    const allKeys = bioMarkerdata.reduce((acc, obj) => {
        return [...acc, ...Object.keys(obj)];
    }, []);

    const uniqueKeys = [...new Set(allKeys)];

    // Generate data keys for the bar chart
    const dataKeys = uniqueKeys.filter(key => key !== 'name').map((key, i) => ({
        dataKey: key,
        fill: key == CancerSiteColors.filter((item) => item.name === key).length > 0 ? CancerSiteColors.filter((item) => item.name === key)[0].code : COLORS[i % COLORS.length]
    }));


    const renderCustomizedLabel = (props) => {
        const { x, y, width, height, value, fill } = props;
        const radius = 13;
        return (
            <g>
                {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill={fill} /> */}
                <text x={x + width / 2} y={y - radius} fontSize={12} fill="#a9a9a9" textAnchor="middle" dominantBaseline="middle">
                    {value}
                </text>
            </g>
        );
    };

    function bioMarkerModal() {
        setbioMarkerOpen(true);
    }
    function bioMarkercloseModal() {
        setbioMarkerOpen(false);
    }

    return (
        <div className="content-wrapper ">
            {downloadLoading && <div className='downloadLoader '>
                <Loader type="spinner-circle" bgColor='black' title={"downloading..."} color={'#FFFFFF'} size={100} />
            </div>
            }
            <div>
            </div>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-1 ">
                        <div className="col px-0">
                            <div className='w-100'></div>
                            <div className='d-flex align-items-center justify-content-between bg-white rounded px-3 py-3'>
                                <div className='title_panel'>
                                    <h4 className='mb-0'><b>HRR</b> <span>(Homologous Recombination Response)</span> <b>Analysis</b></h4>
                                </div>
                                <div className=''>
                                    {/* <div className="btn-group range_button" onClick={() => excelDownload()}>
                                        <img src={topArrow} style={{ marginRight: 5 }} />
                                    </div> */}
                                    <div className="btn-group range_button">
                                        <button type="button" className="btn btn-outline-blue dropdown-toggle" data-toggle="dropdown" data-display="static" aria-expanded="false">
                                            {pdlFilterVal ? pdlFilterVal : 'HRR Status'}
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-lg-right ">
                                            {/* <button onClick={() => filterGraphs('Last 30 Days')} className="dropdown-item" type="button">Last 30 Days</button> */}
                                            <button onClick={() => filterGraphs(filterVal, 'Positive', pdlCloneFilterVal)} className="dropdown-item" type="button">Positive</button>
                                            <button onClick={() => filterGraphs(filterVal, 'Negative', pdlCloneFilterVal)} className="dropdown-item" type="button">Negative</button>
                                            <button onClick={() => AllGraphsList()} className="dropdown-item" type="button">All Status</button>

                                        </div>
                                    </div>
                                    <div className="btn-group range_button">
                                        <button type="button" className="btn btn-outline-blue dropdown-toggle" data-toggle="dropdown" data-display="static" aria-expanded="false">
                                            {pdlCloneFilterVal ? pdlCloneFilterVal : 'HRR Data Type'} {pdlCloneFilterVal ? pdlCloneFilterVal === '22C3' ? 'Dako' : 'Ventana' : ''}
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-lg-right ">
                                            {/* <button onClick={() => filterGraphs('Last 30 Days')} className="dropdown-item" type="button">Last 30 Days</button> */}

                                            <button onClick={() => filterGraphs(filterVal, pdlFilterVal, 'SP142')} className="dropdown-item" type="button">Somatic</button>
                                            <button onClick={() => filterGraphs(filterVal, pdlFilterVal, 'SP263')} className="dropdown-item" type="button">Germline</button>
                                            <button onClick={() => AllGraphsList()} className="dropdown-item" type="button">All Data Type</button>
                                        </div>
                                    </div>

                                    <div className="btn-group range_button">


                                        <button type="button" className="btn btn-outline-blue dropdown-toggle" data-toggle="dropdown" data-display="static" aria-expanded="false">
                                            <i className="zmdi zmdi-calendar-note"></i>{filterVal ? filterVal : 'Time Range'}
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-lg-right ">
                                            <button onClick={() => filterGraphs('Last 1 week', pdlFilterVal,)} className="dropdown-item" type="button">Last 1 Week</button>
                                            <button onClick={() => filterGraphs('Last 15 days', pdlFilterVal, pdlCloneFilterVal)} className="dropdown-item" type="button">Last 15 Days</button>
                                            <button onClick={() => filterGraphs('Last 1 Month', pdlFilterVal, pdlCloneFilterVal)} className="dropdown-item" type="button">Last 1 month</button>
                                            <button onClick={() => filterGraphs('Last 3 Month', pdlFilterVal, pdlCloneFilterVal)} className="dropdown-item" type="button">Last 3 months</button>
                                            <button onClick={() => filterGraphs('Last 6 Month', pdlFilterVal, pdlCloneFilterVal)} className="dropdown-item" type="button">Last 6 months</button>
                                            <button onClick={() => filterGraphs('Last 1 year', pdlFilterVal, pdlCloneFilterVal)} className="dropdown-item" type="button">Last 1 year</button>
                                            <button onClick={() => AllGraphsList()} className="dropdown-item" type="button">All Time</button>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- /.content-header --> */}

            <section className="content">
                <div className="container-fluid px-0">
                    <div className="p-3 rounded bg-white">
                    <div className="row ">
                                <div className="col-lg-3 col-md-6">
                                   <div className='row'>
                                    <div className="col-12 " style={{ 'height': 194 }}>
                                        <div className="card h-100">
                                            <div className='grap-title'>
                                                <h6 className='mb-0 py-1'>Number of cases</h6>
                                                {/* <img src={landScape} /> */}
                                            </div>
                                            <div className='simple-number'>
                                                <h2 className='mb-0'>{commafy(allGraphData?.patientCount)}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12" style={{ 'height': 194, marginTop: 15 }}>
                                        <div className="card h-100">
                                            <div className='grap-title'>
                                                <h6 className='mb-0 py-1'>Primary  Sites</h6>
                                            </div>
                                            <div className='simple-number'>
                                                <h2 className='mb-0'>{commafy(allGraphData?.cancerSiteCount)}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                <div className="col-lg-3 col-md-6 pl-lg-0 pl-md-0 mt-md-0 mt-sm-3 mt-lg-0  pie_charts pdl1_charts pdl1_status" >
                                    <div className="card h-100">
                                        <div className='grap-title'>
                                            <h6 className='mb-0 py-1'>Primary Site Distribution</h6>
                                            {/* <div onClick={() => genderAgeModal()}>
                                            <img src={expand} />
                                        </div> */}
                                        </div>
                                        {allGraphData?.pdl1List &&
                                            <ResponsiveContainer width="100%" height="100%">
                                                <PieChart width={300} height={400}>
                                                    {/* <Legend verticalAlign="top" align="top" /> */}
                                                    <Pie
                                                        data={allGraphData?.pdl1List}
                                                        cx="50%"
                                                        cy="50%"
                                                        labelLine={false}
                                                        label={TMBMSICustomizedLabel}
                                                        outerRadius={'60%'}
                                                        innerRadius={'25%'}
                                                        fill="#8884d8"
                                                        dataKey="value"
                                                    >
                                                        {allGraphData?.pdl1List.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={entry.name === "Positive" ? '#EB455F' : '#323D7B'} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip content={<TmbMsiCustomTooltip />} />
                                                </PieChart>
                                            </ResponsiveContainer>
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 pl-lg-0 mt-3 mt-lg-0 pie_charts pdl1_charts pdl1_status" >
                                    <div className="card h-100">
                                        <div className='grap-title'>
                                            <h6 className='mb-0 py-1'>HRR Status</h6>
                                            {/* <div onClick={() => genderAgeModal()}>
                                            <img src={expand} />
                                        </div> */}
                                        </div>
                                        {allGraphData?.pdl1List &&
                                            <ResponsiveContainer width="100%" height="100%">
                                                <PieChart width={300} height={400}>
                                                    <Legend verticalAlign="top" align="top" />
                                                    <Pie
                                                        data={allGraphData?.pdl1List}
                                                        cx="50%"
                                                        cy="50%"
                                                        labelLine={false}
                                                        label={TMBMSICustomizedLabel}
                                                        outerRadius={'60%'}
                                                        innerRadius={'25%'}
                                                        fill="#8884d8"
                                                        dataKey="value"
                                                    >
                                                        {allGraphData?.pdl1List.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={entry.name === "Positive" ? '#EB455F' : '#323D7B'} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip content={<TmbMsiCustomTooltip />} />
                                                </PieChart>
                                            </ResponsiveContainer>
                                        }

                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 pl-lg-0 pl-md-0 mt-3 mt-sm-3 mt-lg-0  pie_charts pdl1_charts pdl1_clones" >
                                    <div className="card h-100">
                                        <div className='grap-title'>
                                            <h6 className='mb-0 py-1'>HRR Data Type</h6>
                                            {/* <div onClick={() => genderAgeModal()}>
                                            <img src={expand} />
                                        </div> */}
                                        </div>
                                        {allGraphData?.pdl1List &&
                                            <ResponsiveContainer width="100%" height="100%">
                                                <PieChart width={300} height={400}>
                                                    <Legend verticalAlign="top" align="top" />
                                                    <Pie
                                                        data={allGraphData?.pdl1List}
                                                        cx="50%"
                                                        cy="50%"
                                                        labelLine={false}
                                                        label={TMBMSICustomizedLabel}
                                                        outerRadius={'60%'}
                                                        innerRadius={'25%'}
                                                        fill="#8884d8"
                                                        dataKey="value"
                                                    >
                                                        {allGraphData?.pdl1List.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={entry.name === "Positive" ? '#EB455F' : '#323D7B'} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip content={<TmbMsiCustomTooltip />} />
                                                </PieChart>
                                            </ResponsiveContainer>
                                        }
                                    </div>
                                </div>

                        </div>


                        <div className="row p-3" >
                            <div className="col-12 px-0" style={{ 'height': 500, marginTop: 0 }}>
                                <div className="card h-100">
                                    <div className='grap-title'>
                                        <h6 className='mb-0 py-1'>HRR Gene alterations</h6>
                                        <div onClick={() => bioMarkerModal()} >
                                            <img src={expand} className='expandImage' />
                                        </div>
                                    </div>
                                    <div className="row" >
                                        <div style={{ height: 450, width: "80%" }}>
                                            <ResponsiveContainer width="80%" height="100%">
                                                <BarChart
                                                    width={500}
                                                    height={300}
                                                    data={bioMarkerdata}
                                                    layout="vertical"
                                                    margin={{
                                                        top: 20,
                                                        right: 30,
                                                        left: 20,
                                                        bottom: 5,
                                                    }}
                                                >
                                                    {/* <CartesianGrid strokeDasharray="1 1" /> */}
                                                    <XAxis type="number" />
                                                    <YAxis type="category" dataKey="name" />
                                                    <Tooltip />

                                                    {dataKeys.map(key => (
                                                        <Bar key={key.dataKey} dataKey={key.dataKey} fill={key.fill} stackId="a" barSize={20} />
                                                    ))}

                                                    {/* {Object.keys(bioMarkerdata[0]).map((key, index) => {
                                                        if (key !== 'name') {
                                                            return <Bar key={index} dataKey={key} stackId="a" fill={`#${Math.floor(Math.random() * 16777215).toString(16)}`} barSize={20} />;
                                                        }
                                                        return null;
                                                    })} */}
                                                    {/* {keys.map(key => (
                                                        <Bar key={key} dataKey={key} stackId="a" fill="#f4908e" barSize={20} />
                                                    ))} */}
                                                    {/* <Bar dataKey="pv" stackId="a" fill="#f4908e" barSize={20}>
                                                    </Bar>
                                                    <Bar dataKey="as" stackId="a" fill="#7e9e23" barSize={20} />
                                                    <Bar dataKey="uv" stackId="a" fill="#7e96ff" barSize={20} />
                                                    <Bar dataKey="amt" stackId="a" fill="#fdd08e" barSize={20} /> */}
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>
                                        <div style={{ margin: 30 }}>
                                            {CancerSiteColors.map((item, i) => {
                                                return (
                                                    i <= 8 && <div className="d-flex align-items-center">
                                                        <div style={{
                                                            'width': '15px',
                                                            'height': '11px',
                                                            'border-radius': '3px',
                                                            'margin-right': '5px',
                                                            backgroundColor: item.code

                                                        }}></div>
                                                        <div className="label_name">{item.name}</div>
                                                    </div>

                                                )
                                            })}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div style={{}}>
                            <div className="col px-0" style={{ 'height': 520,  marginBottom: 14 }}>
                                <div className="card h-100">
                                    <div className='grap-title'>
                                        <h6 className='mb-0 py-1'>Top 20 Cities </h6>
                                        <div onClick={() => citiesModal()}>
                                            <img src={expand} className='expandImage' />
                                        </div>
                                    </div>
                                    {top20CitiesData &&
                                        <ResponsiveContainer width="100%" height="100%">
                                            <BarChart
                                                width={500}
                                                height={300}
                                                data={top20CitiesData}
                                                margin={{
                                                    top: 30,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                {/* <CartesianGrid strokeDasharray="1 1" /> */}
                                                <XAxis dataKey="name" interval={0} tickLine={false} />
                                                <YAxis />
                                                <Tooltip cursor={{ fill: '#f7f7f7' }} />
                                                {/* <Legend /> */}
                                                {/* <Brush dataKey='name' height={30} stroke="#8884d8"/> */}
                                                <Bar
                                                    dataKey="value" stackId="a" fill="#f4908e" barSize={40}
                                                >
                                                    <LabelList dataKey="value" content={renderCustomizedLabel} />
                                                    {
                                                        top20CitiesData.map((entry, index) => (
                                                            <Cell onClick={() => hospitalLocation(entry)} key={`cell-${index}`} fill={HospitalColor[index % HospitalColor.length]} />
                                                        ))
                                                    }
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    }

                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="col px-0" style={{ 'height': 750, marginTop: 8 }}>
                                <div className="card h-100">
                                    <div className='grap-title'>
                                        <h6 className='mb-0 py-1'>Hospitals ({hospitalName})</h6>
                                        <div className='grap-title'>
                                            <div onClick={() => hospitalModal()}>
                                                <img src={expand} className='expandImage' />
                                            </div>
                                            {/* <div onClick={() => sethospitalShow(false)} style={{ marginLeft: 15 }}>
                                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                                            </div> */}
                                        </div>
                                    </div>
                                    <ResponsiveContainer width="100%" height={650}>
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={hospitalNameData}
                                            margin={{
                                                top: 25,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}
                                        >
                                            {/* <CartesianGrid strokeDasharray="1 1" /> */}
                                            <XAxis dataKey="name"
                                                tick={{ height: 55 }}
                                                interval={0}
                                                angle={-25}
                                                textAnchor="end"
                                                height={130}
                                                tickLine={false}
                                            />
                                            <YAxis />
                                            <Tooltip cursor={{ fill: '#f7f7f7' }} />
                                            {/* <Legend /> */}
                                            <Bar
                                                dataKey="value" stackId="a" fill="#f4908e" barSize={40}
                                            >
                                                <LabelList dataKey="value" content={renderCustomizedLabel} />

                                                {
                                                    hospitalNameData?.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={HospitalColor[index % HospitalColor.length]} />
                                                    ))
                                                }
                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>

                                </div>
                            </div>
                        </div>

                    </div></div>
            </section>


            <Modal
                isOpen={bioMarkerOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={bioMarkercloseModal}
                className="Modal"
                overlayClassName="Overlay"
            >
                <div>
                    <div>
                        <div className='grap-title Modal-top-cn'>
                            <h6 className='mb-0 py-1'>Top 20 Biomarkers across cancer </h6>
                            <div onClick={() => bioMarkercloseModal()}>
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                            </div>
                        </div>
                        <div className="row" >
                            <div style={{ height: 650, width: "80%" }}>
                                <ResponsiveContainer width="80%" height="100%">
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={bioMarkerdata}
                                        layout="vertical"
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        {/* <CartesianGrid strokeDasharray="1 1" /> */}
                                        <XAxis type="number" />
                                        <YAxis type="category" dataKey="name" />
                                        <Tooltip />
                                        <Bar dataKey="pv" stackId="a" fill="#f4908e" barSize={20}>
                                        </Bar>
                                        <Bar dataKey="as" stackId="a" fill="#7e9e23" barSize={20} />
                                        <Bar dataKey="uv" stackId="a" fill="#7e96ff" barSize={20} />
                                        <Bar dataKey="amt" stackId="a" fill="#fdd08e" barSize={20} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                            <div style={{ margin: 30 }}>
                                {CancerSiteColors.map((item) => {
                                    return (
                                        <div className="d-flex align-items-center">
                                            <div style={{
                                                'width': '15px',
                                                'height': '11px',
                                                'border-radius': '3px',
                                                'margin-right': '5px',
                                                backgroundColor: item.code

                                            }}></div>
                                            <div>{item.name}</div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            {/*Cities */}
            <Modal
                isOpen={citiesOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={citiesCloseModal}
                className="Modal"
                overlayClassName="Overlay"
            >
                <div>
                    <div>
                        <div className='grap-title Modal-top-cn'>
                            <h6 className='mb-0 py-1'>Top 20 Cities</h6>
                            <div onClick={() => citiesCloseModal()}>
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                            </div>
                        </div>
                        {top20CitiesData &&
                            <ResponsiveContainer width="100%" height={600}>
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={top20CitiesData}
                                    margin={{
                                        top: 30,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="1 1" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    {/* <Legend /> */}
                                    {/* <Brush dataKey='name' height={30} stroke="#8884d8"/> */}
                                    <Bar
                                        dataKey="value" stackId="a" fill="#f4908e" barSize={40}
                                    >
                                        <LabelList dataKey="value" content={renderCustomizedLabel} />
                                        {
                                            top20CitiesData.map((entry, index) => (
                                                <Cell onClick={() => console.log('entry', entry)} key={`cell-${index}`} fill={HospitalColor[index % HospitalColor.length]} />
                                            ))
                                        }
                                    </Bar>


                                    {/* <Bar dataKey="pv" stackId="a" fill="#f4908e" barSize={40} /> */}
                                    {/* <Bar dataKey="uv" stackId="a" fill="#7e96ff" barSize={40} />
                                        <Bar dataKey="amt" stackId="a" fill="#fdd08e" barSize={40} /> */}
                                </BarChart>
                            </ResponsiveContainer>
                        }

                    </div>
                </div>
            </Modal>


            {/*hospital */}
            <Modal
                isOpen={hospitalOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={hospitalCloseModal}
                className="Modal"
                overlayClassName="Overlay"
            >
                <div>
                    <div>
                        <div className='grap-title Modal-top-cn'>
                            <h6 className='mb-0 py-1'>Hospitals ({hospitalName})</h6>
                            <div onClick={() => hospitalCloseModal()}>
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                            </div>
                        </div>
                        <ResponsiveContainer width="100%" height={600}>
                            <BarChart
                                width={500}
                                height={300}
                                data={hospitalNameData}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    // bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="1 1" />
                                <XAxis dataKey="name"
                                    interval={0}
                                    angle={-35}
                                    height={120}
                                    textAnchor="end" />
                                <YAxis />
                                <Tooltip />
                                {/* <Legend /> */}
                                <Bar
                                    dataKey="value" stackId="a" fill="#f4908e" barSize={40}
                                >
                                    <LabelList dataKey="value" content={renderCustomizedLabel} />
                                    {
                                        hospitalNameData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={HospitalColor[index % HospitalColor.length]} />
                                        ))
                                    }
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
export default HRR;