import React from 'react';
import { PieChart, Pie, Tooltip, Cell, Sector, ResponsiveContainer } from 'recharts';

const EmptyPieChart = ({ height, width, outerRadius, innerRadius }) => {
    return (
        <ResponsiveContainer width="100%" height="100%" className='primary_pie'>
            <PieChart width={width} height={height}>
                <Pie
                    data={[{ value: 1 }]}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    outerRadius={outerRadius}
                    innerRadius={innerRadius}
                    stroke="#5373ff"
                    strokeWidth={1}
                    fill="none"
                >
                    <Sector
                        cx="50%"
                        cy="50%"
                        innerRadius={0}
                        outerRadius={100}
                        startAngle={0}
                        endAngle={360}
                        fill="none"
                    />
                </Pie>
                {/* <Tooltip /> */}
            </PieChart>
        </ResponsiveContainer>
    );
};

const Chart = ({ height, width, outerRadius, innerRadius }) => {
    return (
        <div>
            {/* <EmptyPieChart height={height} width={width} outerRadius={outerRadius} innerRadius={innerRadius} /> */}
        </div>
    );
};

export default Chart;