
import React, { useEffect, useContext, useState, useCallback } from "react";
import PDL from "./Components/PDL";
import { toast } from "react-toastify";
import { setDefaultHeader, apiCall } from "../../utils/httpClient";
import ApiEndPoint from "../../utils/apiEndPoints";
import { GLOBAL_URL } from '../../utils/constants'
import { CanSiteColors, COLORS } from '../../utils/constants'
import { LoaderContext } from "../../Context/userContext";

const PDLAnyalsisScreen = () => {
    //   const { signInFun, signOutFun } = React.useContext(AuthContext);
    const [IsLoading, setIsLoading] = useContext(LoaderContext);
    //  const [userDetails, setUserDetails] = useContext(UserDatailContext);
    const [filterVal, setFilterVal] = useState('')
    const [pdlFilterVal, setPdlFilterVal] = useState('')
    const [pdlCloneFilterVal, setPdlCloneFilterVal] = useState('')
    const [hospitalNameData, setHospitalNameData] = useState([]);
    const [hospitalName, setHospitalName] = useState('');
    const [drugTherapy, setDrugTherapy] = useState([]);
    const [biologicalTherapy, setBiologicalTherapy] = useState([])
    const [drugsCount, setDrugsCount] = useState([])
    const [top10CanSiteList, setTop10CanSiteList] = useState([])
    const [drugTherapyTotal, setDrugTherapyTotal] = useState([]);
    const [biologicalTherapyTotal, setBiologicalTherapyTotal] = useState([]);
    const [drugsCountTotal, setDrugsCountTotal] = useState([])
    const [MorphologyCount, setMorphologyCount] = useState([])
    const [morphologyTotal, setMorphologyTotal] = useState([])
    const [ageGender, setAgeGender] = useState([])
    const [totalPatientCount, setTotalPatientCount] = useState([])
    const [tobaccoCount, setTobaccoCount] = useState([])
    const [familyHistoryVal, setFamilyHistoryVal] = useState([])
    const [cancerSiteCount, setCancerSiteCount] = useState([])
    const [cansiteval, setCanSiteVal] = useState([])
    const [cancersiteTotal, setCancerSiteTotal] = useState([])
    const [deceasedVal, setDeceasedVal] = useState([])
    const [msiValue, setMsiValue] = useState([])
    const [msiTotal, setMsiTotal] = useState([])
    const [tmbValue, setTmbValue] = useState([])
    const [tmbTotal, setTmbTotal] = useState([])
    const [pdlStatusVal, setPdlStatusVal] = useState([])
    const [pdlStatusTotal, setPdlStatusTotal] = useState([])
    const [pdlCloneVal, setPdlCloneVal] = useState([])
    const [pdlCloneValTotal, setPdlCloneValTotal] = useState([])
    const [pdlMultipleCloneVal, setPdlMultipleCloneVal] = useState([])
    const [firstdegree, setFirstDegree] = useState([])
    const [primaryFilterVal, setPrimaryFilterVal] = useState('');
    const [drugFilterVal, setDrugFilterVal] = useState('')
    const [biologicalFilterVal, setBiologicalFilterVal] = useState('');
    const [hospCity, setHospCity] = useState([]);
    const [hospCityTotal, setHospCityTotal] = useState([]);
    const [hospName, setHospName] = useState('Mumbai')
    const [hospNameData, setHospNameData] = useState()
    const [cancerStage, setCancerStage] = useState(null)
    const [geneCancer, setGeneCancer] = useState(null)
    const [recistCriteria, setRecistCriteria] = useState([]);
    const [recistCriteriaTotal, setRecistCriteriaTotal] = useState([]);
    const [originalCancerSearch, setOriginalCancerSearch] = useState([]);
    const [cann, setCann] = useState([]);
    const [CancersiteValue, setCancersiteValue] = useState([]);
    const [hospitalValue, setHospitalValue] = useState([])
    const [cansiteName, setCansiteName] = useState([]);
    const [psiteValue, setPsiteValue] = useState([]);

    useEffect(() => {
        pdlHospfun()
        //searchCancerType()
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [hospName])

    useEffect(() => {
        handleAllApis();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [pdlFilterVal, filterVal, pdlCloneFilterVal, primaryFilterVal, drugFilterVal, biologicalFilterVal]);

    // function bodyDataFun(dt) {
    //     const humanData = [
    //         { name: 'Lung', value: 0, id: 'Lung' },
    //         { name: 'Breast', value: 0, id: 'Breast' },
    //         { name: 'Pancreas', value: 0, id: 'Pancreas' },
    //         { name: 'Stomach', value: 0, id: 'Stomach' },
    //         { name: 'Liver and intrahepatic bile ducts', value: 0, id: 'Liver and intrahepatic bile ducts' },
    //         { name: 'Esophagus', value: 0, id: 'Esophagus' },
    //         { name: 'Gallbladder', value: 0, id: 'Gallbladder' },
    //         { name: "Large Intestine", value: 0, id: "Large Intestine" },
    //         { name: "Ovary", value: 0, id: "Ovary" },
    //         { name: "Corpus Uteri", value: 0, id: "Corpus Uteri" }

    //     ]

    //     const newState = humanData.map(obj => {
    //         if (obj.name === dt?.filter((item) => { return item.name === obj.name })[0]?.name) {
    //             return { ...obj, value: dt?.filter((item) => { return item.name === obj.name })[0]?.value };
    //         }
    //         return obj;
    //     });
    //     //  const sortedData = newState?.sort((a, b) => b.value - a.value);
    //     console.log("humantopp10", newState);
    //     return newState
    // }

    function transformedData(array) {
        const newarr = array?.map((item) => ({
            name: item[0] || null,
            value: item[1],
        }))
        return newarr
    }
    function transssData(array) {
        const newarr = array?.map((item) => ({
            name: item[0] || null,
            value: item[1],
            id: item[0] || null
        }))
        return newarr
    }

    function renameKeys(obj, newKeys) {
        const keyValues = Object.keys(obj).map(key => {
            const newKey = newKeys[key] || key;
            return { [newKey]: obj[key] };
        });
        return Object.assign({}, ...keyValues);
    }

    function MorphologyResponse(dt) {
        setMorphologyCount(transformedData(dt?.payLoad).filter((item) => item.value > 0))
        const initialValue = 0;
        const MorphologyTotal = dt.payLoad?.reduce(
            (accumulator, currentValue) => accumulator + currentValue[1],
            initialValue
        );
        console.log("morphototal", MorphologyTotal)
        setMorphologyTotal(MorphologyTotal)
    }
    function cancerSiteCountResponse(data) {
        setCanSiteVal(transformedData(data?.payLoad))
        setPsiteValue(transformedData(data?.payLoad).filter((item) => item.value > 50))
        setCancerSiteCount(transformedData(data?.payLoad).filter((item) => item.value > 12))
        console.log("ccc", transformedData(data?.payLoad).filter((item) => item.value > 12))
        const initialValue = 0;
        const CancerSiteTotal = data.payLoad?.reduce(
            (accumulator, currentValue) => accumulator + currentValue[1],
            initialValue
        );
        console.log("CancerSiteTotal", CancerSiteTotal)
        setCancerSiteTotal(CancerSiteTotal)
    }
    // const searchCancerType = (event) => {
    //     const searchTerm = event.target.value;
    //     setSearchInp(searchTerm);
    //     const updatedList = originalCancerSearch.filter(item => {
    //         return item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
    //     });
    //     console.log('searchTerm:', searchTerm);
    //     console.log('updatedList:', updatedList);
    //     setCancerSearch(updatedList);
    // };


    function msiCountResponse(data) {
        setMsiValue(transformedData(data?.payLoad).filter((item) => item.value > 0))
        const initialValue = 0;
        const MsiTotal = data.payLoad?.reduce(
            (accumulator, currentValue) => accumulator + currentValue[1],
            initialValue
        );
        console.log("MsiTotal", MsiTotal)
        setMsiTotal(MsiTotal)
    }

    function tmbCountResponse(data) {
        const tmb = transformedData(data?.payLoad).filter((item) => item.value > 0)
        console.log("tmbb", tmb)
        setTmbValue(tmb)
        const initialValue = 0;
        const TmbTotal = data.payLoad?.reduce(
            (accumulator, currentValue) => accumulator + currentValue[1],
            initialValue
        );
        console.log("TmbTotal", TmbTotal)
        setTmbTotal(TmbTotal)
    }

    function pdlStatusResponse(data) {
        setPdlStatusVal(transformedData(data?.payLoad).filter((item) => item.value > 3))
        console.log("PdlStatusResponse", transformedData(data?.payLoad).filter((item) => item.value > 3))
        const initialValue = 0;
        const PdlStatusTotal = data.payLoad?.reduce(
            (accumulator, currentValue) => accumulator + currentValue[1],
            initialValue
        );
        console.log("PdlStatusTotal", PdlStatusTotal)
        setPdlStatusTotal(PdlStatusTotal)
    }

    function pdlCloneResponse(data) {
        setPdlCloneVal(transformedData(data?.payLoad).filter((item) => item.value > 3))
        console.log("PdlCloneResponse", transformedData(data?.payLoad).filter((item) => item.value > 3))

        const initialValue = 0;
        const PdlCloneTotal = data.payLoad?.reduce(
            (accumulator, currentValue) => accumulator + currentValue[1],
            initialValue
        );
        console.log("PdlCloneTotal", PdlCloneTotal)
        setPdlCloneValTotal(PdlCloneTotal)
    }
    function drugCountResponse(data) {
        setDrugsCount(transformedData(data?.payLoad).filter((item) => item.value > 0))
        console.log("immuno", transformedData(data?.payLoad).filter((item) => item.value > 0))
        const initialValue = 0;
        const DrugsCountTotal = data.payLoad?.reduce(
            (accumulator, currentValue) => accumulator + currentValue[1],
            initialValue
        );
        console.log("DrugsCountTotal", DrugsCountTotal)
        setDrugsCountTotal(DrugsCountTotal)
    }

    function drugTherapyResponse(data) {
        setDrugTherapy(data?.payLoad.filter((item) => item.value > 0))
        const initialValue = 0;
        const DrugTherapyTotal = data.payLoad?.reduce(
            (accumulator, currentValue) => accumulator + currentValue.value,
            initialValue
        );
        console.log("DrugTherapyTotal", DrugTherapyTotal)
        setDrugTherapyTotal(DrugTherapyTotal)
    }
    function biologicalTherapyResponse(data) {
        setBiologicalTherapy(data?.payLoad.filter((item) => item.value > 0))
        const initialValue = 0;
        const BiologicalTherapyTotal = data.payLoad?.reduce(
            (accumulator, currentValue) => accumulator + currentValue.value,
            initialValue
        );
        console.log("BiologicalTherapyTotal", BiologicalTherapyTotal)
        setBiologicalTherapyTotal(BiologicalTherapyTotal)
    }

    function AgeGenderResponse(data) {
        const newMaleFemale = { maleCount: "Male", femaleCount: 'Female' };
        var genderAgeGroupObj = [];
        data.payLoad?.map((item) => {
            return (
                genderAgeGroupObj.push(renameKeys(item, newMaleFemale))
            )
        })
        console.log("genderage", genderAgeGroupObj);

        const genderAge = genderAgeGroupObj?.filter((item) => {
            return item.Female !== 0 || item.Male !== 0;
        })
        console.log("genderagefilter", genderAge)
        setAgeGender(genderAge)
    }
    const pdlHospfun = async (value) => {
        const params = {
            dateFilter: filterVal ? filterVal : null,
            pdl1StatusFilter: pdlFilterVal ? pdlFilterVal : null,
            pdl1CloneFilter: pdlCloneFilterVal ? pdlCloneFilterVal : null,
            cancerSiteFilter: primaryFilterVal ? primaryFilterVal : null,
            drugFilter: drugFilterVal ? drugFilterVal : null,
            biologicalDrugFilter: biologicalFilterVal ? biologicalFilterVal : null,
        };

        try {
            const { data } = await apiCall('POST', ApiEndPoint.PdlHospitalName + `${hospName}`, params);
            console.log("PdlHosp", data);

            if (data) {
                setHospNameData(transformedData(data?.payLoad));
                setIsLoading(false);
            } else {
                setIsLoading(false);
                toast.error(data, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        } catch (error) {
            console.error("Error occurred in pdlHospfun:", error);
            setIsLoading(false);
            // Handle error
        }
    };
    const fetchfun = useCallback(async () => {
        const params = {
            dateFilter: filterVal || null,
            pdl1StatusFilter: pdlFilterVal || null,
            pdl1CloneFilter: pdlCloneFilterVal || null,
            cancerSiteFilter: primaryFilterVal || null,
            drugFilter: drugFilterVal || null,
            biologicalDrugFilter: biologicalFilterVal || null,
        };

        setIsLoading(true); // Start loading state
        try {
            const { data } = await apiCall('POST', ApiEndPoint.PdlCancerSiteCount, params);
            console.log("PdlHosp", data);

            if (data) {
                setOriginalCancerSearch(transformedData(data?.payLoad));
            } else {
                toast.error(data, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (error) {
            console.error("Error occurred in fetchfun:", error);
            toast.error("An error occurred while fetching data.", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } finally {
            setIsLoading(false); // Ensure loading state is reset
        }
    }, [filterVal, pdlFilterVal, pdlCloneFilterVal, primaryFilterVal, drugFilterVal, biologicalFilterVal, setIsLoading]);

    useEffect(() => {
        fetchfun()
    }, [fetchfun])
    // const fetchfun = async () => {
    //     const params = {
    //         dateFilter: filterVal ? filterVal : null,
    //         pdl1StatusFilter: pdlFilterVal ? pdlFilterVal : null,
    //         pdl1CloneFilter: pdlCloneFilterVal ? pdlCloneFilterVal : null,
    //         cancerSiteFilter: primaryFilterVal ? primaryFilterVal : null,
    //         drugFilter: drugFilterVal ? drugFilterVal : null,
    //         biologicalDrugFilter: biologicalFilterVal ? biologicalFilterVal : null,
    //     };

    //     try {
    //         const { data } = await apiCall('POST', ApiEndPoint.PdlCancerSiteCount, params);
    //         console.log("PdlHosp", data);

    //         if (data) {
    //             //  const filteredNames = data?.payLoad.filter(([name, value]) => value > 12).map(([name]) => name);
    //             //  console.log("FilteredNames", filteredNames);
    //             setOriginalCancerSearch(transformedData(data?.payLoad))
    //             // setOriginalCancerSearch(transformedData(data?.payLoad).filter((item) => item.value >12))
    //             setIsLoading(false);
    //         } else {
    //             setIsLoading(false);
    //             toast.error(data, {
    //                 position: toast.POSITION.TOP_RIGHT
    //             });
    //         }
    //     } catch (error) {
    //         console.error("Error occurred in pdlHospfun:", error);
    //         setIsLoading(false);
    //         // Handle error
    //     }
    // };

    const handleAllApis = async () => {
        setIsLoading(true)
        const params = {
            dateFilter: filterVal ? filterVal : null,
            pdl1StatusFilter: pdlFilterVal ? pdlFilterVal : null,
            pdl1CloneFilter: pdlCloneFilterVal ? pdlCloneFilterVal : null,
            cancerSiteFilter: primaryFilterVal.length ? primaryFilterVal : null,
            //cancerSiteFilter:primaryFilterVal.length > 0 ? primaryFilterVal.map((x) => x.value) : null,
            drugFilter: drugFilterVal ? drugFilterVal : null,
            biologicalDrugFilter: biologicalFilterVal ? biologicalFilterVal : null,
        };

        const apiPromises = [
            apiCall('POST', ApiEndPoint.MorphologyCount, params),
            apiCall('POST', ApiEndPoint.PdlAgeGender, params),
            apiCall('POST', ApiEndPoint.DrugsCount, params),
            apiCall('POST', ApiEndPoint.DrugsTherapy, params),
            apiCall('POST', ApiEndPoint.BiologicalTherapy, params),
            apiCall('POST', ApiEndPoint.PdlTotalPatients, params),
            apiCall('POST', ApiEndPoint.PdlTobaccoCount, params),
            apiCall('POST', ApiEndPoint.PdlFamilyHistoryCount, params),
            apiCall('POST', ApiEndPoint.firstDegreeCount, params),
            apiCall('POST', ApiEndPoint.PdlCancerSiteCount, params),
            apiCall('POST', ApiEndPoint.PdlDeceasedCount, params),
            apiCall('POST', ApiEndPoint.PdlMsiCount, params),
            apiCall('POST', ApiEndPoint.PdlTmbCount, params),
            apiCall('POST', ApiEndPoint.PdlStatusCount, params),
            apiCall('POST', ApiEndPoint.PdlCloneCount, params),
            apiCall('POST', ApiEndPoint.Top10CancerSite, params),
            apiCall('POST', ApiEndPoint.PdlMultipleCloneCount, params),
            apiCall('POST', ApiEndPoint.PdlHospitalCity, params),
            apiCall('POST', ApiEndPoint.PdlHospitalName + `${hospName}`, params),
            apiCall('POST', ApiEndPoint.PdlCancerStage, params),
            apiCall('POST', ApiEndPoint.PdlGeneCancerSite, params),
            apiCall('POST', ApiEndPoint.PdlRecistCriteria, params),
            apiCall('GET', ApiEndPoint.NumberOfCancerSites),
            apiCall('GET', ApiEndPoint.NumberOfHospitals),

        ];

        try {
            const results = await Promise.allSettled(apiPromises);

            results.forEach((result, index) => {
                if (result.status === 'fulfilled') {
                    try {
                        const data = result.value.data;
                        switch (index) {
                            case 0:
                                MorphologyResponse(data);
                                break;
                            case 1:
                                AgeGenderResponse(data);
                                break;
                            case 2:
                                drugCountResponse(data);
                                break;
                            case 3:
                                drugTherapyResponse(data);
                                break;
                            case 4:
                                biologicalTherapyResponse(data);
                                break;
                            case 5:
                                setTotalPatientCount(data?.payLoad);
                                console.log("no of ptnts", data?.payLoad)
                                break;
                            case 6:
                                setTobaccoCount(data?.payLoad);
                                break;
                            case 7:
                                setFamilyHistoryVal(data?.payLoad);
                                break;
                            case 8:
                                setFirstDegree(data?.payLoad)
                                break;
                            case 9:
                                cancerSiteCountResponse(data);
                                break;
                            case 10:
                                setDeceasedVal(data?.payLoad);
                                break;
                            case 11:
                                msiCountResponse(data);
                                break;
                            case 12:
                                tmbCountResponse(data);
                                break;
                            case 13:
                                pdlStatusResponse(data);
                                break;
                            case 14:
                                pdlCloneResponse(data);
                                break;
                            case 15:
                                const transdata = transssData(data?.payLoad)
                                console.log("transData", transdata)
                                setTop10CanSiteList(transdata)
                                break;
                            case 16:
                                setPdlMultipleCloneVal(data?.payLoad);
                                break;
                            case 17:
                                //pdlhospitalResponse(data)
                                const trandata = transformedData(data?.payLoad)
                                console.log("hospitaldata", trandata)
                                setHospCity(trandata)
                                setHospName(trandata[0]?.name);
                                // apiCall('POST', ApiEndPoint.PdlHospitalName + `${hospName}`,params);
                                console.log("HospName", trandata[0]?.name)
                                const initValue = 0;
                                const HospTotal = data.payLoad?.reduce(
                                    (accumulator, currentValue) => accumulator + currentValue[1],
                                    initValue
                                );
                                setHospCityTotal(HospTotal)
                                // setHostCity(data?.payLoad);
                                break;
                            case 18:
                                //   pdlHospfun()
                                setHospNameData(transformedData(data?.payLoad));
                                break;
                            case 19:
                                const filteredData = data?.payLoad.filter(item => item.biomarkerName !== "NA" && item.biomarkerName !== "Others");
                                //setCancerStage(filteredData);
                                console.log("cancerStagess", filteredData)
                                // const cancerStageData = data?.payLoad.map(item => item.cancerSiteCountList);
                                const updatedCommonBiomarkers = filteredData.map((item) => {
                                    const totalCancerSiteCount = item.cancerSiteCountList.reduce((acc, site) => acc + site.cancerSiteCount, 0);
                                    return {
                                        ...item,
                                        total: totalCancerSiteCount,
                                    };
                                });
                                const allCancerSites = [...new Set(data?.payLoad.flatMap(stage => stage.cancerSiteCountList.map(site => site.cancerSiteName)))];
                                console.log("ttt", updatedCommonBiomarkers)
                                setCancerStage(updatedCommonBiomarkers);
                                //  setCann(dataKeys)
                                setCann(allCancerSites)
                                console.log("cann", allCancerSites)
                                break;
                            case 20:
                                const upddCommonBiomarkers = data?.payLoad.map((item) => {
                                    const totalCanSiteCount = item.cancerSiteCountList.reduce((acc, site) => acc + site.cancerSiteCount, 0);
                                    return {
                                        ...item,
                                        total: totalCanSiteCount,
                                    };
                                });
                                const uniqueCancerSiteNames = new Set(data.payLoad.flatMap(stage => stage.cancerSiteCountList.map(site => site.cancerSiteName)));
                                const allCanSitenames = Array.from(uniqueCancerSiteNames).map((name, index) => {
                                    const colorObject = CanSiteColors.find(item => item.name === name);
                                    const color = colorObject ? colorObject.code : COLORS[index % COLORS.length];
                                    return {
                                        dataKey: name,
                                        fill: color
                                    };
                                });
                                // var color = ''
                                // const allCanSitenames = [...new Set(data?.payLoad.flatMap(stage => stage.cancerSiteCountList.map(site => site.cancerSiteName)))].map((key, i) => (
                                //     color = CanSiteColors.filter((item) => item.name === key).length > 0 ? CanSiteColors.filter((item) => item.name === key)[0].code : '',
                                //     {
                                //         dataKey: key,

                                //         fill: color ? color : COLORS[i % COLORS.length]
                                //     }));
                                setCansiteName(allCanSitenames)
                                console.log("cansitenames", allCanSitenames)
                                console.log("genee", upddCommonBiomarkers)
                                setGeneCancer(upddCommonBiomarkers);
                                // setGeneCancer(data?.payLoad)
                                console.log("geneCancer", data?.payLoad)
                                break;
                            case 21:
                                const transfdata = transformedData(data?.payLoad)
                                console.log("recistCriteria", transfdata)
                                setRecistCriteria(transfdata)
                                const initialValue = 0;
                                const resistTotal = data.payLoad?.reduce(
                                    (accumulator, currentValue) => accumulator + currentValue[1],
                                    initialValue
                                );
                                console.log("resistTotal", resistTotal)
                                setRecistCriteriaTotal(resistTotal)
                                break;
                            case 22:
                                setCancersiteValue(data?.payLoad);
                                console.log("cansiteval", data?.payLoad)
                                break;
                            case 23:
                                setHospitalValue(data?.payLoad);
                                console.log("hospitalval", data?.payLoad)
                                break;
                            default:
                                console.log(`No handler for API function ${index + 1}`);
                        }
                    }
                    catch (handlerError) {
                        console.error(`Error handling response for API call ${index + 1}:`, handlerError)
                    }
                } else {
                    console.error(`Error from API call ${index + 1}:`, result.reason);
                    toast.error(`Error fetching data for API at index ${index + 1}`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });

        } catch (error) {
            console.error('Error in  setting API calls:', error);
        } finally {
            setIsLoading(false);
            // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    }


    const pdlexcel = async () => {
        setIsLoading(true)
        //  setDownloading(true)
        setDefaultHeader(true)
        // excelValfun()
        const anchor = document.createElement('a');
        //let headers = new Headers();
        const token = await localStorage.getItem('userData');
        fetch(GLOBAL_URL + ApiEndPoint.PdlExcelData, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',

            },
            body: JSON.stringify({
                dateFilter: filterVal ? filterVal : null,
                pdl1StatusFilter: pdlFilterVal ? pdlFilterVal : null,
                pdl1CloneFilter: pdlCloneFilterVal ? pdlCloneFilterVal : null,
                cancerSiteFilter: primaryFilterVal.length ? primaryFilterVal : null,
                // cancerSiteFilter:primaryFilterVal.length > 0 ? primaryFilterVal.map((x) => x.value) : null,
                drugFilter: drugFilterVal ? drugFilterVal : null,
                biologicalDrugFilter: biologicalFilterVal ? biologicalFilterVal : null,
            }),
        })
            // excelValfun() 
            .then(response => response.blob())
            .then(data => {
                console.log('data: ', data);
                setIsLoading(false)
                //  setDownloading(false)
                //  excelValfun()
                if (data.type === "application/octet-stream") {
                    // setRocheData(data.size)
                    let objectUrl = window.URL.createObjectURL(data);
                    anchor.href = objectUrl;
                    anchor.download = 'RocheData.xlsx';
                    anchor.click();
                    anchor.remove();
                    window.URL.revokeObjectURL(objectUrl);
                    toast.success('Successfully downloaded !', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    setIsLoading(false)
                    toast.error('Something went wrong', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            });
    }
    const excelDownload = async () => {
        setIsLoading(true)
        //setDownloadLoading(true)
        const anchor = document.createElement('a');
        let headers = new Headers();
        const token = await localStorage.getItem('userData');
        headers.append('Authorization', `Bearer ${token}`);
        headers.append('Content-Type', 'application/json');
        // console.log('headers: ', headers);
        fetch(GLOBAL_URL + ApiEndPoint.pdl1ExportExcel, { headers })
            .then(response => response.blob())
            .then(data => {
                console.log('data: ', data);
                setIsLoading(false)
                if (data.type === "application/octet-stream") {
                    let objectUrl = window.URL.createObjectURL(data);
                    anchor.href = objectUrl;
                    anchor.download = 'PDL_Excel.xlsx';
                    anchor.click();
                    anchor.remove();
                    window.URL.revokeObjectURL(objectUrl);
                    toast.success('Successfully downloaded !', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    setIsLoading(false)
                    //setDownloadLoading(false)
                    toast.error('Something went wrong', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            });
    }

    return (
        <>
            <PDL
                setFilterVal={setFilterVal}
                setPdlFilterVal={setPdlFilterVal}
                setPdlCloneFilterVal={setPdlCloneFilterVal}
                filterVal={filterVal}
                pdlCloneFilterVal={pdlCloneFilterVal}
                biologicalTherapyTotal={biologicalTherapyTotal}
                drugsCountTotal={drugsCountTotal}
                drugTherapyTotal={drugTherapyTotal}
                pdlFilterVal={pdlFilterVal}
                drugTherapy={drugTherapy}
                biologicalTherapy={biologicalTherapy}
                drugsCount={drugsCount}
                top10CanSiteList={top10CanSiteList}
                hospitalNameData={hospitalNameData}
                setHospitalNameData={setHospitalNameData}
                hospitalName={hospitalName}
                setHospitalName={setHospitalName}
                excelDownload={excelDownload}
                MorphologyCount={MorphologyCount}
                morphologyTotal={morphologyTotal}
                ageGender={ageGender}
                totalPatientCount={totalPatientCount}
                tobaccoCount={tobaccoCount}
                cancerSiteCount={cancerSiteCount}
                cancersiteTotal={cancersiteTotal}
                deceasedVal={deceasedVal}
                msiValue={msiValue}
                msiTotal={msiTotal}
                tmbValue={tmbValue}
                tmbTotal={tmbTotal}
                familyHistoryVal={familyHistoryVal}
                firstdegree={firstdegree}
                pdlStatusVal={pdlStatusVal}
                pdlStatusTotal={pdlStatusTotal}
                pdlCloneVal={pdlCloneVal}
                pdlCloneValTotal={pdlCloneValTotal}
                pdlMultipleCloneVal={pdlMultipleCloneVal}
                pdlexcel={pdlexcel}
                setPrimaryFilterVal={setPrimaryFilterVal}
                primaryFilterVal={primaryFilterVal}
                setDrugFilterVal={setDrugFilterVal}
                drugFilterVal={drugFilterVal}
                setBiologicalFilterVal={setBiologicalFilterVal}
                biologicalFilterVal={biologicalFilterVal}
                hospName={hospName}
                hospCity={hospCity}
                hospCityTotal={hospCityTotal}
                hospNameData={hospNameData}
                setHospNameData={setHospNameData}
                setHospName={setHospName}
                pdlHospfun={() => pdlHospfun()}
                cancerStage={cancerStage}
                geneCancer={geneCancer}
                cann={cann}
                recistCriteria={recistCriteria}
                recistCriteriaTotal={recistCriteriaTotal}
                IsLoading={IsLoading}
                originalCancerSearch={originalCancerSearch}
                cansiteval={cansiteval}
                CancersiteValue={CancersiteValue}
                hospitalValue={hospitalValue}
                cansiteName={cansiteName}
                psiteValue={psiteValue}
            />
        </>
    )
}
export default PDLAnyalsisScreen
