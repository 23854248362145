import React, { useEffect } from "react";
import ThankuPage from "./Components/ThankuPage";
import { useNavigate } from 'react-router-dom';

//import { setDefaultHeader, apiCall } from "../../utils/httpClient";
//import ApiEndPoint from "../../utils/apiEndPoints";
//import { AuthContext } from "../../Context/context";

const ThankuPageScreen = () => {
    const history = useNavigate()
    useEffect(() => {
        setTimeout(() => {
            history('/')
        }, 4000);
    }, [])

    return (
        <>
            <ThankuPage />
        </>
    )
}
export default ThankuPageScreen