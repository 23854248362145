import React, { useState, useContext } from "react";
import Login from "./Components/Login";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { setDefaultHeader, apiCall } from "../../utils/httpClient";
import ApiEndPoint from "../../utils/apiEndPoints";
import { AuthContext } from "../../Context/context";
import { LoaderContext, UserDatailContext } from "../../Context/userContext";

const LoginScreen = () => {
    const history = useNavigate()
    const [, setIsLoading] = useContext(LoaderContext);
    const [, setUserDetails] = useContext(UserDatailContext);
    const { signInFun } = React.useContext(AuthContext);
    const navigate = useNavigate();
    //  const { state } = useLocation();

    const [loginDetails, setLoginDetails] = useState({
        email: '',
        password: ''
    })
    const [validError, setValidError] = useState(false)
    const [validEmail, setValidEmail] = useState(false)
    const [passwordShow, setPasswordShow] = useState(true)
    const mails1 = ["user1@elucidata.com", "user1@indegene.com", "user1@iqvia.com", "user2@elucidata.com", "shankara@4bcresearch.com"]
    //  const value = mails1?.includes(userDetails?.email)
    const handleChangeEmail = (event) => {
        setLoginDetails({
            ...loginDetails,
            email: event.target.value
        })
        setValidEmail(false)
    };

    const handleChangePassword = (event) => {
        setLoginDetails({
            ...loginDetails,
            password: event.target.value
        })
    };

    const validatoin = () => {
        if (!loginDetails.email.length > 0) {
            setValidError(true)
            return false;
        }
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!loginDetails.email.match(validRegex)) {
            setValidEmail(true)
            return false;
        } else {
            setValidEmail(false)
        }
        if (!loginDetails.password.length > 0) {
            setValidError(true)
            return false;
        }
        setValidError(false)
        return true
    }

    const lastLogin = async () => {
        const { data } = await apiCall('GET', ApiEndPoint.LAST_LOGIN)
        console.log("lastLogin", data);
        if (data.success) {
            console.log("success", data.success)
        } else {
            setIsLoading(false)
            toast.error(data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const loginFun = async () => {

        const valid = validatoin()
        if (valid) {
            const params = {
                email: loginDetails.email,
                password: loginDetails.password,
            };
            setIsLoading(true)
            const { data } = await apiCall('POST', ApiEndPoint.SIGNIN, params)
            if (data.success) {

                var userLoginRes = [
                    {
                        userToken: "01",
                        username: data.payLoad.authToken,
                    },
                ];
                await signInFun(userLoginRes);
                setUserDetails(data?.payLoad)

                await localStorage.setItem("userData", data?.payLoad.authToken);
                await localStorage.setItem("userDetails", JSON.stringify(data?.payLoad));
                await setDefaultHeader('Authorization', `Bearer ${data?.payLoad.authToken}`);
                // await setDefaultHeader("Authorization", "Bearer" + ' ' + data?.payLoad.authToken);
                lastLogin();
                setIsLoading(false)
                if (data?.payLoad.email === "roche@user") {
                    navigate("/PDLAnyalsis");
                }
                else if (mails1?.includes(data?.payLoad.email)) {
                    navigate("/Dashboard")
                }
                else {
                    navigate("/");
                }
                //    navigate("/Dashboard")
            } else {
                setIsLoading(false)
                // setLoginDetails({
                //     ...loginDetails,
                //     email: '',
                //     password: '',
                // })
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    function passwordShowFun() {
        setPasswordShow(!passwordShow)
    }

    function naviRegister() {
        history('/registration')
    }
    function naviForgotPassword() {
        history('/forgotPassword')
    }

    return (
        <>
            <Login
                handleChangeEmail={(e) => handleChangeEmail(e)}
                handleChangePassword={(e) => handleChangePassword(e)}
                passwordShowFun={() => passwordShowFun()}
                naviRegister={() => naviRegister()}
                naviForgotPassword={() => naviForgotPassword()}
                passwordShow={passwordShow}
                loginDetails={loginDetails}
                loginFun={loginFun}
                validError={validError}
                validEmail={validEmail}
            />
        </>
    )
}
export default LoginScreen