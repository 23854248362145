import React from 'react';
import LandingPage from './Components/LandingPage';

export default function LandingScreen() {
    return (
        <div>
            <LandingPage />
        </div>
    );
}
