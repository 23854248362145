import React, { useState } from "react";
import ForgotPassword from "./Components/ForgotPassword";
import { useNavigate } from 'react-router-dom';

import { setDefaultHeader, apiCall } from "../../utils/httpClient";
import ApiEndPoint from "../../utils/apiEndPoints";
import { AuthContext } from "../../Context/context";

const ForgotPasswordScreen = () => {
    const history = useNavigate()
    const [validError, setValidError] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [validEmail, setValidEmail] = useState(false)
    const [validPassAndConfirm, setValidPassAndConfirm] = useState(false)
    const [showResetPassword, setShowResetPassword] = useState(false)

    const [passwordShow, serPasswordShow] = useState(true)

    const handleChangeEmail = (event) => {
        setEmail(event.target.value)
        setValidEmail(false)
    };

    const handleChangePassword = (event) => {
        setPassword(event.target.value)
    };

    const handleConfirmPassword = (event) => {
        setConfirmPassword(event.target.value)
        setValidPassAndConfirm(false)
    };

    const validatoin = () => {
        if (!email.length > 0) {
            setValidError(true)
            return false;
        }
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!email.match(validRegex)) {
            setValidEmail(true)
            return false;
        } else {
            setValidEmail(false)
        }
        setValidError(false)
        return true
    }

    const sendEmailFun = async () => {
        const valid = validatoin()
        if (valid) {
            setShowResetPassword(true)
            // const params = {
            //     email: email,
            // };
            // const { data } = await apiCall('POST', '/user/login', params)
            // if (data.success) {

            // } else {
            // }
        }
    }

    const validatoinResetPass = () => {
        if (!email.length > 0) {
            setValidError(true)
            return false;
        }
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!email.match(validRegex)) {
            setValidEmail(true)
            return false;
        } else {
            setValidEmail(false)
        }
        if (!password.length > 0) {
            setValidError(true)
            return false;
        }
        if (!confirmPassword.length > 0) {
            setValidError(true)
            return false;
        }
        if (confirmPassword !== password) {
            setValidPassAndConfirm(true)
            return false;
        }
        setValidError(false)
        setValidPassAndConfirm(false)
        return true
    }


    const resetPaawordFun = async () => {
        const valid = validatoinResetPass()
        if (valid) {
            history('/')
            // const params = {
            //     email: email,
            // };
            // const { data } = await apiCall('POST', '/user/login', params)
            // if (data.success) {

            // } else {
            // }
        }
    }


    function passwordShowFun() {
        serPasswordShow(!passwordShow)
    }

    function navToLogin() {
        history('/')
    }
    return (
        <>
            <ForgotPassword
                handleChangeEmail={(e) => handleChangeEmail(e)}
                email={email}

                handleChangePassword={(e) => handleChangePassword(e)}
                password={password}

                handleConfirmPassword={(e) => handleConfirmPassword(e)}
                confirmPassword={confirmPassword}

                passwordShowFun={() => passwordShowFun()}
                passwordShow={passwordShow}
                sendEmailFun={sendEmailFun}
                validError={validError}
                navToLogin={navToLogin}
                validEmail={validEmail}
                showResetPassword={showResetPassword}
                resetPaawordFun={resetPaawordFun}
                validPassAndConfirm={validPassAndConfirm}
            />
        </>
    )
}
export default ForgotPasswordScreen