// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success_compo {
  min-height: 400px;
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #e8ffe0 0%, rgba(233, 247, 255, 0) 100%);
}
.success_compo h6 {
  font-weight: 400;
}
.success_compo h6 a {
  color: #4464f0;
  cursor: pointer;
  font-size: 1rem !important;
}
`, "",{"version":3,"sources":["webpack://./src/Screen/ThankuPage/Components/style.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,4EAA4E;AAC9E;AACA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,eAAe;EACf,0BAA0B;AAC5B","sourcesContent":[".success_compo {\r\n  min-height: 400px;\r\n  display: flex;\r\n  align-items: center;\r\n  background: linear-gradient(180deg, #e8ffe0 0%, rgba(233, 247, 255, 0) 100%);\r\n}\r\n.success_compo h6 {\r\n  font-weight: 400;\r\n}\r\n.success_compo h6 a {\r\n  color: #4464f0;\r\n  cursor: pointer;\r\n  font-size: 1rem !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
