import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
} from 'recharts';
import { ResponsiveSankey } from '@nivo/sankey'
//import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import { ComposableMap, Geographies, Geography, Marker, Line as MapLine, } from 'react-simple-maps';

const geoUrl = require("./world.json");

const data0 = {
    "nodes": [
        {
            "name": "Cases"
        },
        {
            "name": "Male",
        },
        {
            "name": "Female",
        },
        {
            "name": "Site1",
        },
        {
            "name": "Site2",
        },
        {
            "name": "Site3",
        },
        {
            "name": "Site4",
        },
        {
            "name": "TD53",
        },
        {
            "name": "EGFR",
        },
        {
            "name": "BRCA1",
        },
        {
            "name": "ERBBB2",
        },
    ],
    links: [
        { source: "0", target: "1", value: 37281 },
        { source: "0", target: "2", value: 35410 },
        //  { source: "Cases", target: "ERBBB2", value: 354 },
        { source: "1", target: "3", value: 35170 },
        { source: "1", target: "4", value: 35414 },
        { source: "2", target: "5", value: 54170 },
        { source: "2", target: "6", value: 35170 },
        { source: "3", target: "8", value: 91741 },
        { source: "4", target: "9", value: 64291 },
        { source: "5", target: "7", value: 41701 },
        { source: "6", target: "10", value: 35662 },
    ],

};

const data = {
    "nodes": [
        {
            "id": "Cases",
            // "nodeColor": "hsl(9, 70%, 50%)"
        },
        {
            "id": "Male",
            // "nodeColor": "hsl(302, 70%, 50%)"
        },
        {
            "id": "Female",
            "nodeColor": "hsl(307, 70%, 50%)"
        },
        {
            "id": "Site1",
            // "nodeColor": "hsl(333, 70%, 50%)"
        },
        {
            "id": "Site2",
            // "nodeColor": "hsl(188, 70%, 50%)"
        },
        {
            "id": "Site3",
            //  "nodeColor": "hsl(73, 70%, 50%)"
        },
        {
            "id": "Site4",
            //"nodeColor": "hsl(55, 70%, 50%)"
        },
        {
            "id": "TD53",
            // "nodeColor": "hsl(689, 70%, 50%)"
        },
        {
            "id": "EGFR",
            // "nodeColor": "hsl(346, 70%, 50%)"
        },
        {
            "id": "BRCA1",
            // "nodeColor": "hsl(98, 70%, 50%)"
        },
        {
            "id": "ERBBB2",
            // "nodeColor": "hsl(224, 70%, 50%)"
        },


    ],
    links: [
        { source: "Cases", target: "Male", value: 37281 },
        { source: "Cases", target: "Female", value: 35410 },
        //  { source: "Cases", target: "ERBBB2", value: 354 },
        { source: "Male", target: "Site1", value: 35170 },
        { source: "Male", target: "Site2", value: 35414 },
        { source: "Female", target: "Site3", value: 54170 },
        { source: "Female", target: "Site4", value: 35170 },
        { source: "Site1", target: "EGFR", value: 91741 },
        { source: "Site2", target: "BRCA1", value: 64291 },
        { source: "Site3", target: "TD53", value: 41701 },
        { source: "Site4", target: "ERBBB2", value: 35662 },
    ],

};


const data1 = [
    {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];

export default function LandingPage() {
    const navigate = useNavigate();
    const [hoveredMarker, setHoveredMarker] = useState(null);
    const [tooltipContent, setTooltipContent] = useState("");
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    // const markers = [
    //     { markerOffset: -15, name: "India", coordinates: [78.9629, 20.5937] }, // [longitude, latitude]
    //     { markerOffset: -15, name: "China", coordinates: [104.1954, 35.8617] },
    //     { markerOffset: 15, name: "Nepal", coordinates: [83.945, 28.3949] },
    //     { markerOffset: 15, name: "Dubai", coordinates: [55.2708, 25.2048] },
    // ];
    // const markers = [
    //     { name: "Uzbekistan", coordinates: [64.5858, 41.3775], markerOffset: -15 },
    //     { name: "Nepal", coordinates: [80.2707, 28.3949], markerOffset: -10 },
    //     { name: "India", coordinates: [77.2090, 28.6139], markerOffset: -5 },
    //     { name: "Bangladesh", coordinates: [90.3563, 23.6858], markerOffset: 0 },
    //     { name: "Philippines", coordinates: [121.7740, 12.8797], markerOffset: 5 },
    //     { name: "Malaysia", coordinates: [101.9758, 4.2105], markerOffset: 10 },
    // ];
    const markers = [
        { id: "Uzbekistan", coordinates: [64.5853, 41.3775] },
        { id: "Nepal", coordinates: [80.1794, 28.3949] },
        { id: "Bangladesh", coordinates: [90.3563, 23.685] },
        { id: "India", coordinates: [78.9629, 20.5937] },
        { id: "Philippines", coordinates: [121.774, 12.8797] },
        { id: "Malaysia", coordinates: [101.9758, 4.2105] },
    ];

    const connections = [
        { from: "Uzbekistan", to: "India" },
        { from: "Nepal", to: "Bangladesh" },
        { from: "Malaysia", to: "Philippines" },
        { from: "Malaysia", to: "Bangladesh" },
        { from: "India", to: "Philippines" },

    ];
    const markers1 = [
        { name: "Bangalore", coordinates: [77.5946, 12.9716] },
        { name: "Dubai", coordinates: [55.2708, 25.2048] },
        { name: "Philippines", coordinates: [121.7740, 12.8797] },
        { name: "Nepal", coordinates: [80.2718, 28.3949] },
    ];

    // const countriesToHighlight = [
    //     "Nepal",
    //     "India",
    //     "Bangladesh",
    //     "Philippines",
    //     "Venezuela",
    //     "Iran"
    // ];
    const countriesToHighlight = [
        "India",
        "Bangladesh",
        "Nepal",
        "Philippines",
    ];

    const countriesWithLightBlue = [
        "Venezuela",
        "Iran",
        "Turkey",
        "Sri Lanka",
        "Uzbekistan",
    ];
    const markers2 = [
        { name: "Bangalore", coordinates: [77.5946, 12.9716], tooltip: "Current Location: Bangalore" },
        { name: "Dubai", coordinates: [55.2708, 25.2048], tooltip: "Upcoming Location: Dubai" },
        { name: "Philippines", coordinates: [121.7740, 13.4125], tooltip: "Upcoming Location: Philippines" },
        { name: "Nepal", coordinates: [80.2220, 28.3949], tooltip: "Upcoming Location: Nepal" },
    ];

    const markerOffsetBase = 15;
    markers.forEach((marker, index) => {
        marker.markerOffset = index % 2 === 0 ? -markerOffsetBase : markerOffsetBase;
    });
    return (
        <>
            <h1>OncoViz Landing Page</h1>
            <button className="btn btn-info login_btn-color" onClick={() => navigate("/login")}>Login</button>
            <button className="btn btn-info login_btn-color" onClick={() => navigate("/registration")}>Signup</button>
            <div className="content-wrapper">
                <div className='mt-3'>
                    <div className="col px-0 mb-3" style={{ 'height': 550, }}>
                        <div className="card h-100">
                            <div className='grap-title'>
                                <h6 className='mb-0 py-1'>Line Chart</h6>
                            </div>
                            <ResponsiveContainer width="100%" height="90%">
                                <LineChart width={500} height={300} data={data1}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                                    <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                                </LineChart>
                            </ResponsiveContainer>

                        </div>
                    </div>
                    <div className="col px-0 mb-3" style={{ 'height': 600, }}>
                        <div className="card h-100">
                            <div className='grap-title'>
                                <h6 className='mb-0 py-1'>Sankey Chart</h6>
                            </div>
                            <ResponsiveSankey
                                data={data}
                                margin={{ top: 40, right: 160, bottom: 70, left: 50 }}
                                align="justify"
                                colors={{ scheme: 'category10' }}
                                nodeOpacity={1}
                                nodeHoverOthersOpacity={0.35}
                                nodeThickness={18}
                                nodeSpacing={24}
                                nodeBorderWidth={0}
                                nodeBorderColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            0.8
                                        ]
                                    ]
                                }}
                                nodeBorderRadius={3}
                                linkOpacity={0.5}
                                linkHoverOthersOpacity={0.1}
                                linkContract={3}
                                enableLinkGradient={true}
                                labelPosition="outside"
                                labelOrientation="vertical"
                                labelPadding={16}
                                labelTextColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            1
                                        ]
                                    ]
                                }}

                                legends={[
                                    {
                                        anchor: 'bottom-right',
                                        direction: 'column',
                                        translateX: 130,
                                        itemWidth: 100,
                                        itemHeight: 14,
                                        itemDirection: 'right-to-left',
                                        itemsSpacing: 2,
                                        itemTextColor: '#999',
                                        symbolSize: 14,
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemTextColor: '#000'
                                                }
                                            }
                                        ]
                                    }
                                ]}
                            />
                        </div>
                    </div>
                    <div className="col px-0 mb-3" style={{ width: '100%', overflow: 'hidden', height: '800px' }}>
                        <div className="card h-100 d-flex flex-column">
                            <div className='grap-title'>
                                <h6 className='mb-0 py-1'>World Map</h6>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '50px', padding: '0 20px' }}>
                                <div style={{ flex: '0 0 60%' }}> {/* Space on the left */}
                                    <ComposableMap style={{ width: '100%', height: 'auto' }}>
                                        <Geographies geography={geoUrl}>
                                            {({ geographies }) =>
                                                geographies.map((geo) => {
                                                    const countryName = geo.properties.name;
                                                    const isHighlighted = countriesToHighlight.includes(countryName);
                                                    const isLightBlue = countriesWithLightBlue.includes(countryName);

                                                    return (
                                                        <Geography
                                                            key={geo.rsmKey}
                                                            geography={geo}
                                                            fill={isHighlighted ? "blue" : isLightBlue ? "#ADD8E6" : "#EAEAEC"}
                                                            onMouseEnter={(e) => {
                                                                const tooltip = document.getElementById("tooltip");
                                                                tooltip.innerHTML = countryName;
                                                                tooltip.style.display = "block";
                                                                tooltip.style.left = `${e.clientX + 10}px`;
                                                                tooltip.style.top = `${e.clientY - 60}px`;
                                                            }}
                                                            onMouseLeave={() => {
                                                                const tooltip = document.getElementById("tooltip");
                                                                tooltip.style.display = "none";
                                                            }}
                                                        />
                                                    );
                                                })
                                            }
                                        </Geographies>
                                        {markers2.map(({ name, coordinates, tooltip }) => (
                                            <Marker key={name} coordinates={coordinates}>
                                                <svg height={16} width={10} style={{ cursor: 'pointer' }}>
                                                    <path
                                                        d="M5 0 C7 0, 10 2, 10 5 C10 8, 5 15, 5 15 C5 15, 0 8, 0 5 C0 2, 2 0, 5 0 Z"
                                                        fill="#FF5733"
                                                        onMouseEnter={(e) => {
                                                            const tooltipElem = document.getElementById("tooltip");
                                                            tooltipElem.innerHTML = tooltip;
                                                            tooltipElem.style.display = "block";
                                                            tooltipElem.style.left = `${e.clientX + 10}px`;
                                                            tooltipElem.style.top = `${e.clientY - 60}px`; // Adjusted for markers
                                                        }}
                                                        onMouseLeave={() => {
                                                            const tooltipElem = document.getElementById("tooltip");
                                                            tooltipElem.style.display = "none";
                                                        }}
                                                    />
                                                    <circle cx={5} cy={7} r={3} fill="white" />
                                                </svg>
                                            </Marker>
                                        ))}
                                    </ComposableMap>

                                    <div
                                        id="tooltip"
                                        style={{
                                            position: "absolute",
                                            background: "white",
                                            padding: "5px",
                                            borderRadius: "5px",
                                            display: "none",
                                            pointerEvents: "none",
                                            boxShadow: "0 2px 5px rgba(0,0,0,0.2)"
                                        }}
                                    />

                                    {/* Legend Section */}
                                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '-50px', marginBottom: '20px' }}>
                                        {countriesToHighlight.filter(country => country !== "Russia").map(country => (
                                            <div key={country} style={{ display: 'flex', alignItems: 'center', width: '30%', margin: '3px 0' }}>
                                                <div style={{
                                                    width: '17px',
                                                    height: '17px',
                                                    backgroundColor: "blue",
                                                    border: '1px solid #000',
                                                    marginRight: '10px',
                                                    borderRadius: '3px',
                                                }} />
                                                <span>{country}</span>
                                            </div>
                                        ))}
                                        {countriesWithLightBlue.filter(country => country !== "Russia").map(country => (
                                            <div key={country} style={{ display: 'flex', alignItems: 'center', width: '30%', margin: '3px 0' }}>
                                                <div style={{
                                                    width: '17px',
                                                    height: '17px',
                                                    backgroundColor: "#ADD8E6",
                                                    border: '1px solid #000',
                                                    marginRight: '10px',
                                                    borderRadius: '3px',
                                                }} />
                                                <span>{country}</span>
                                            </div>
                                        ))}
                                        {/* <div style={{ display: 'flex', alignItems: 'center', width: '30%', margin: '5px 0' }}>
                                            <div style={{
                                                width: '20px',
                                                height: '20px',
                                                backgroundColor: "#EAEAEC",
                                                border: '1px solid #000',
                                                marginRight: '10px'
                                            }} />
                                            <span>Other Countries</span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>



                </div>
            </div>

        </>
    );
}

