import React from 'react';
import Comapany from './../../../assets/images/4bc.svg';
import cases from './../../../assets/images/new_home/case2.svg';
import tests from './../../../assets/images/new_home/Tests.svg';
import hospital from './../../../assets/images/new_home/Hospitals.svg';
import Clinicians from './../../../assets/images/new_home/Clinicians.svg';
import Countries from './../../../assets/images/new_home/Countries.svg';
import Unique_Genes from './../../../assets/images/new_home/Gene unique.svg';
// import Mutation from './../../../assets/images/new_home/Mutation_icon.svg';
import vcf from './../../../assets/images/new_home/VCF files.svg';
import unique_variants from './../../../assets/images/new_home/Unique variants.svg';
import mute from './../../../assets/images/new_home/Mutation_icon.svg';
import RNA from './../../../assets/images/new_home/mrna.svg';
import DNA from './../../../assets/images/new_home/DNA icon.svg';
import PDL1 from './../../../assets/images/new_home/PD-L1.svg';
import Clinical_events from './../../../assets/images/new_home/Clinical Data.svg';
import avarage_Duration from './../../../assets/images/new_home/Average Duration.svg';
import Bio_Specimen from './../../../assets/images/new_home/Bio Specimen.svg';
import digital_pathology from './../../../assets/images/new_home/Digital Pathology.svg';
import Cancer_sites from './../../../assets/images/new_home/Cancer sites.svg';
import patients_icon from './../../../assets/images/new_home/Patients icon.svg';
import Serum from './../../../assets/images/new_home/Serum.svg';
import bg1 from './../../../assets/images/Rectangle 11811.svg';
import bg2 from './../../../assets/images/Rectangle 11814.svg';
import bg3 from './../../../assets/images/Rectangle 11813.svg';
import Somatic_Germline from './../../../assets/images/new_home/Somatic and germline icon.svg';
import Germline_icon from './../../../assets/images/new_home/Germline Icon.svg';
import Mrna1 from './../../../assets/images/new_home/mrna1.svg';
import Loader from "react-js-loader";
import CountUp from "react-countup";

export default function Home1(props) {
    const { homeData, IsLoading, totalpatients, clinicalVal } = props
    return (
        <div className="content-wrapper home_bg">
            {IsLoading && <div className='downloadLoader '>
                <Loader type="spinner-circle" bgColor='black' title={"loading..."} color={'#FFFFFF'} size={100} />
            </div>
            }
            <div className="content-wrapper_inner p_top-15">




                {/* Comapany Overview */}
                <div className="row mb-0 mx-0  company_panel">


                    <div className="col px-0">
                        <section className="content">
                            <div className='mask_bg'>
                                <img src={bg1} alt="icons" />
                            </div>


                            <div className="container-fluid px-0">
                                <div className='rounded mb-2'>
                                    <h4 className='title_clr'>Coverage  </h4>

                                    <h6 className='mb-3 font-weight-bold thick_text'>
                                        {/* <span className='title_bg'></span> */}
                                    </h6>
                                    <div className='row cards_row'>
                                        <div className='col-lg-4 col-md-6 col-sm-12  card_main'>
                                            <div className='card_inner first_card'>
                                                <span className='span1'><img src={cases} alt="Cases" /></span>
                                                <div className='card_child'>
                                                    <div className='card_text p-4 animate_h4'>
                                                        {/* <h5 class="counter_number">{homeData?.noOfPatients}</h5> */}


                                                        <CountUp duration={5} className='counter_number' start={10894} end={totalpatients} />


                                                        <p>Patients</p>
                                                    </div>
                                                    <span className='span2 patients_icon'><img className='w-180' src={patients_icon} alt="Patients" />
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6 col-sm-12 card_main '>
                                            <div className='card_inner fourth_card'>
                                                <span className='span1'><img src={Clinicians} alt="icons" /></span>
                                                <div className='card_child'>
                                                    <div className='card_text p-4 animate_h4'>
                                                        {/* <h5>{homeData?.noOfDoctors}</h5> */}
                                                        <CountUp duration={5} className='counter_number' start={500} end={840} />
                                                        <p>Clinicians</p>
                                                    </div>
                                                    <span className='span2'><img src={Clinicians} alt="icons" />
                                                    </span>
                                                </div>

                                            </div>
                                        </div>



                                        <div className='col-lg-4 col-md-12 col-sm-12 card_main p_top-32'>
                                            <div className='card_inner third_card'>
                                                <span className='span1'><img src={hospital} alt="icons" /></span>
                                                <div className='card_child'>
                                                    <div className='card_text p-4 animate_h4'>
                                                        {/* <h5>{homeData?.noOfHospitals}</h5> */}
                                                        <CountUp duration={5} className='counter_number' start={500} end={homeData?.noOfHospitals} />
                                                        <p>Referral Hospitals/Clinics</p>
                                                    </div>
                                                    <span className='span2'><img src={hospital} alt="icons" />
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='w-100 pt-32'></div>
                                        <div className='col-lg-4 col-md-6 col-sm-12 ps_top-32 card_main'>
                                            <div className='card_inner sec_card'>
                                                <span className='span1'><img src={Cancer_sites} alt="icons" /></span>
                                                <div className='card_child'>
                                                    <div className='card_text p-4 animate_h4'>
                                                        {/* <h5>{homeData?.noOfCancerSites}</h5> */}
                                                        <CountUp duration={5} className='counter_number' start={45} end={homeData?.noOfCancerSites} />
                                                        <p>Primary cancer sites</p>
                                                    </div>
                                                    <span className='span2'><img src={Cancer_sites} alt="icons" />
                                                    </span>
                                                </div>

                                            </div>

                                        </div>

                                        {/* 2nd row */}

                                        <div className='col-lg-4 col-md-6 col-sm-12 ps_top-32 card_main'>
                                            <div className='card_inner sixth_card'>
                                                <span className='span1'><img src={avarage_Duration} alt="icons" /></span>
                                                <div className='card_child'>
                                                    <div className='card_text p-4 animate_h4'>
                                                        {/* <h5 className='counter_number'>29 Months</h5> */}
                                                        <CountUp duration={5} className='counter_number' start={350} end={540} /><span className='days'>Days</span>
                                                        <p>Avg. Longitudinal Follow-up Time </p>
                                                    </div>
                                                    <span className='span2'><img src={avarage_Duration} alt="icons" />
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6 col-sm-12 ps_top-32 card_main p_top-32'>
                                            <div className='card_inner fifth_card'>
                                                <span className='span1'><img src={Countries} alt="icons" /></span>
                                                <div className='card_child'>
                                                    <div className='card_text p-4 animate_h4'>
                                                        {/* <h5 className='counter_number'>8+</h5> */}
                                                        <CountUp duration={5} className='counter_number' start={5} end={8} /><span className='plus_icon'>+</span>
                                                        <p>Countries</p>
                                                    </div>
                                                    <span className='span2'><img src={Countries} alt="icons" />
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        {/* <div className='col card_main'>
                                                <div className='card_inner sixth_card'>
                                                    <span className='span1'><img src={genes} /></span>
                                                    <div className='card_child'>
                                                         <div className='card_text p-4 animate_h4'>
                                                            <h5>13,230</h5>
                                                            <p>Genes</p>
                                                        </div>
                                                        <span className='span2'><img src={genes} />
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col card_main'>
                                                <div className='card_inner sev_card'>
                                                    <span className='span1'><img src={Unique_Genes} /></span>
                                                    <div className='card_child'>
                                                         <div className='card_text p-4 animate_h4'>
                                                            <h5>97</h5>
                                                            <p>Unique Genes</p>
                                                        </div>
                                                        <span className='span2'><img src={Unique_Genes} />
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col card_main'>
                                                <div className='card_inner eight_card'>
                                                    <span className='span1'><img src={Mutations} /></span>
                                                    <div className='card_child'>
                                                         <div className='card_text p-4 animate_h4'>
                                                            <h5>65</h5>
                                                            <p>Variants</p>
                                                        </div>
                                                        <span className='span2'><img src={Mutations} />
                                                        </span>
                                                    </div>

                                                </div>
                                            </div> */}

                                    </div>
                                </div>
                            </div>



                        </section>

                    </div>

                </div>

                {/* Laboratory  */}
                <div className="row mb-0 mx-0  lab_panel pt-32">
                    <div className="col px-0">
                        <section className="content">
                            <div className='mask_bg'>
                                <img src={bg2} alt="icons" />
                            </div>
                            <div className="container-fluid px-0">
                                <div className='rounded mb-2'>
                                    <h4 className='title_clr'>Omics Information Attributes</h4>
                                    <div className='row cards_row lab_panel'>

                                        {/* <div className='col card_main'>
                                            <div className='card_inner first_card'>
                                                <span className='span1'><img src={unique_Mutations} /></span>
                                                <div className='card_child'>
                                                     <div className='card_text p-4 animate_h4'>
                                                        <h5>84</h5>
                                                        <p>Unique non benign Mutations</p>
                                                    </div>
                                                    <span className='span2'><img src={unique_Mutations} />
                                                    </span>
                                                </div>

                                            </div>
                                        </div> */}
                                        <div className='col-lg-4 col-md-6 col-sm-12 card_main'>
                                            <div className='card_inner first_card'>
                                                <span className='span1'><img src={tests} alt="icons" /></span>
                                                <div className='card_child'>
                                                    <div className='card_text p-4 animate_h4'>
                                                        {/* <h5>{homeData?.noOfTest}</h5> */}
                                                        <CountUp duration={5} className='counter_number' start={10525} end={homeData?.noOfTest} />
                                                        <p>Tests (Somatic, Germline, Liquid)</p>
                                                    </div>
                                                    <span className='span2'><img src={tests} className='rotate_45' alt="icons" />
                                                    </span>
                                                </div>

                                            </div>

                                        </div>
                                        <div className='col-lg-4 col-md-6 col-sm-12 card_main ps_top-32'>
                                            <div className='card_inner sec_card'>
                                                <span className='span1'><img src={DNA} alt="icons" /></span>
                                                <div className='card_child'>
                                                    <div className='card_text p-4 animate_h4'>
                                                        {/* <h5 className='counter_number'>2,550</h5> */}
                                                        <CountUp duration={5} className='counter_number' start={11100} end={12790} />
                                                        {/* <div className="panel_align">
                                                            <p>RNA sequencings</p>
                                                            <div className="panel_align  panel_Rna">
                                                                <span>Whole Exome: 592 </span>
                                                                <span> Panel: 1221</span>
                                                            </div>
                                                        </div> */}

                                                        <div className="panel_align">
                                                            <p>DNA-seq data</p>
                                                            <div className="panel_align panel_Dna panel_flexy">
                                                                <span><span className='bold_txt'>Whole Exome:</span> 2665 </span>
                                                                <span> <span className='bold_txt'>Targeted :</span> 10125</span>
                                                            </div>
                                                        </div>
                                                        {/* <p><a href='#' className='card_drop'>DNA</a></p> */}
                                                    </div>
                                                    <span className='span2 dna_seq'><img src={DNA} className='roatate_82' alt="icons" />
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-12 col-sm-12 p_top-32 card_main'>
                                            <div className='card_inner third_card'>
                                                <span className='span1'><img src={RNA} alt="icons" /></span>
                                                <div className='card_child'>
                                                    <div className='card_text p-4 animate_h4'>
                                                        {/* <h5 className='counter_number'>7520</h5> */}
                                                        <CountUp duration={5} className='counter_number' start={2365} end={2540} />
                                                        <div className="panel_align">
                                                            <p>RNA-seq data</p>
                                                            <div className="panel_align panel_Dna panel_flexy">
                                                                <span><span className='bold_txt'>Whole Exome :</span>831 </span>
                                                                <span> <span className='bold_txt'>Targeted :</span> 1709</span>
                                                            </div>
                                                        </div>

                                                        {/* <p>RNA tests</p>
                                                        <h8>Whole Exome: 1430  Panel: 1130</h8> */}
                                                        {/* <p><a href='#' className='card_drop'>RNA</a></p> */}
                                                    </div>
                                                    <span className='span2 rna_image'><img src={RNA} alt="icons" />
                                                    </span>
                                                </div>


                                            </div>
                                        </div>
                                        <div className='w-100 pt-32'></div>
                                        <div className='col-lg-4 col-md-6 col-sm-12 card_main'>
                                            <div className='card_inner fifth_card'>
                                                <span className='span1'><img src={vcf} alt="icons" /></span>
                                                <div className='card_child'>
                                                    <div className='card_text p-4 animate_h4'>
                                                        {/* <h5 className='counter_number'>13,230</h5> */}
                                                        <CountUp duration={5} className='counter_number' start={5227} end={3294} />
                                                        <p>Unique Genes</p>
                                                    </div>
                                                    <span className='span2'><img src={Unique_Genes} className='roatate_45' alt="icons" />
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6 col-sm-12 card_main ps_top-32'>
                                            <div className='card_inner fourth_card'>
                                                <span className='span1'><img src={vcf} alt="icons" /></span>
                                                <div className='card_child'>
                                                    <div className='card_text p-4 animate_h4'>
                                                        {/* <h5 className='counter_number'>13,230</h5> */}
                                                        <CountUp duration={5} className='counter_number' start={809500} end={909897} />

                                                        <p>Variants</p>
                                                    </div>
                                                    <span className='span2'><img src={mute} className='roatate_45' alt="icons" />
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6 col-sm-12 card_main p_top-32'>
                                            <div className='card_inner eight_card'>
                                                <span className='span1'><img src={vcf} alt="icons" /></span>
                                                <div className='card_child'>
                                                    <div className='card_text p-4 animate_h4'>
                                                        {/* <h5 className='counter_number'>13,230</h5> */}
                                                        <CountUp duration={5} className='counter_number' start={562846} end={521945} />

                                                        <p>Unique Variants</p>
                                                    </div>
                                                    <span className='span2'><img src={unique_variants} className='roatate_45' alt="icons" />
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='w-100 pt-32'></div>
                                        <div className='col-lg-4 col-md-6 col-sm-12 card_main p_top-32'>
                                            <div className='card_inner sixth_card'>
                                                <span className='span1'><img src={Clinical_events} alt="icons" /></span>
                                                <div className='card_child'>
                                                    <div className='card_text p-4 animate_h4'>
                                                        {/* <h5 className='counter_number'>90,669</h5> */}
                                                        <CountUp duration={5} className='counter_number' start={107415} end={homeData?.newRecordEventCount} />
                                                        <p>Clinical Data Parameters</p>
                                                    </div>
                                                    <span className='span2'><img src={Clinical_events} alt="icons" />
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6 col-sm-12 card_main p_top-32'>
                                            <div className='card_inner seven_card'>
                                                <span className='span1'><img src={PDL1} alt="icons" /></span>
                                                <div className='card_child'>
                                                    <div className='card_text p-4 animate_h4'>
                                                        {/* <h5 className='counter_number'>2451</h5> */}
                                                        <CountUp duration={5} className='counter_number' start={2359} end={clinicalVal} />

                                                        <p>PD-L1 tests</p>
                                                    </div>
                                                    <span className='span2 pd_l1'><img src={PDL1} alt="icons" />
                                                    </span>
                                                </div>

                                            </div>
                                        </div>




                                    </div>



                                </div>
                            </div>
                        </section>
                    </div>

                </div >


                {/* Help */}

                <div div className="row mb-0 mx-0  help-panel pt-32" >
                    <div className="col px-0">
                        <section className="content">
                            <div className='mask_bg'>
                                <img src={bg3} alt="icons" />
                            </div>
                            <div className="container-fluid px-0">

                                <div className=' rounded mb-2'>
                                    <h4 className='title_clr'>Biobank</h4>

                                    <div className='row cards_row help_panel'>
                                        <div className='col-4 card_main'>
                                            <div className='card_inner first_card'>
                                                {/* <span className='span1'><img src={Somatic_Germline} /></span> */}
                                                <div className='card_child'>
                                                    <div className='card_text p-4 animate_h4'>
                                                        {/* <h5 className='counter_number'>8300</h5> */}
                                                        <CountUp duration={5} className='counter_number' start={7000} end={8092} />

                                                        <p>Somatic + Germline DNA Extract from same patient</p>
                                                    </div>
                                                    <span className='span2'>
                                                        {/* <img src={Bio_Specimen} /> */}
                                                        <img className='w-110' src={Somatic_Germline} alt="icons" />

                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col card_main'>
                                            <div className='card_inner sec_card'>
                                                {/* <span className='span1'><img src={Germline_icon} /></span> */}
                                                <div className='card_child'>
                                                    <div className='card_text p-4 animate_h4'>
                                                        {/* <h5 className='counter_number'>8300</h5> */}
                                                        <CountUp duration={5} className='counter_number' start={4201} end={4741} />

                                                        <p>Germline only DNA Extract</p>
                                                    </div>
                                                    <span className='span2'>
                                                        {/* <img src={Bio_Specimen} /> */}
                                                        <img className='w-110' src={Germline_icon} alt="icons" />

                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6 col-sm-12 card_main'>
                                            <div className='card_inner fifth_card'>
                                                <span className='span1'><img src={Bio_Specimen} alt="icons" /></span>
                                                <div className='card_child'>
                                                    <div className='card_text p-4 animate_h4'>
                                                        {/* <h5 className='counter_number'>8300</h5> */}
                                                        <CountUp duration={5} className='counter_number' start={2231} end={2792} />

                                                        <p>RNA Extract</p>
                                                    </div>
                                                    <span className='span2'>
                                                        {/* <img src={Bio_Specimen} /> */}
                                                        <img className='w-110' src={Mrna1} alt="icons" />

                                                    </span>
                                                </div>

                                            </div>
                                        </div>



                                        {/* 2nd row */}
                                        <div className='w-100 pt-32'></div>
                                        <div className='col-lg-4 col-md-6 col-sm-12 card_main'>
                                            <div className='card_inner third_card'>
                                                <span className='span1'><img src={Bio_Specimen} alt="icons" /></span>
                                                <div className='card_child'>
                                                    <div className='card_text p-4 animate_h4'>
                                                        {/* <h5 className='counter_number'>8300</h5> */}
                                                        <CountUp duration={5} className='counter_number' start={2354} end={2819} />

                                                        <p>FFPE blocks</p>
                                                    </div>
                                                    <span className='span2'>
                                                        {/* <img src={Bio_Specimen} /> */}
                                                        <img className='w-110' src={cases} alt="icons" />

                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                        <div className='col-lg-4 col-md-6 col-sm-12 card_main ps_top-32'>
                                            <div className='card_inner fourth_card'>
                                                <span className='span1'><img src={digital_pathology} alt="icons" /></span>
                                                <div className='card_child'>
                                                    <div className='card_text p-4 animate_h4'>
                                                        {/* <h5 className='counter_number'>7500</h5> */}
                                                        <CountUp duration={5} className='counter_number' start={3200} end={3400} />

                                                        <p>Histopathology Slides </p>
                                                    </div>
                                                    <span className='span2'><img src={digital_pathology} alt="icons" />
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6 col-sm-12 card_main ps_top-32'>
                                            <div className='card_inner sixth_card'>
                                                <span className='span1'><img src={Cancer_sites} alt="icons" /></span>
                                                <div className='card_child'>
                                                    <div className='card_text p-4 animate_h4'>
                                                        {/* <h5 className='counter_number'>7500</h5> */}
                                                        <CountUp duration={5} className='counter_number' start={918} end={991} />

                                                        <p>Buffy coat</p>
                                                    </div>
                                                    <span className='span2'><img src={Serum} alt="icons" />
                                                    </span>
                                                </div>

                                            </div>
                                        </div>


                                    </div>



                                </div>
                            </div>
                        </section >




                    </div>

                </div >









                <div className='d-flex align-items-center justify-content-between py-2'>
                    <div className='note_text p-4'>
                        {/* <p className='mb-0 '> <span></span>Note : The main header may account for 100%, while the sub header represents specific components that may individually</p>
                        <p className='mb-0 pl-2'> exceed 100% due to their shared presence within a broader category and coexistence.</p> */}
                    </div>
                    <div className='note_img mr-5'>
                        <img src={Comapany} alt="icons" />
                    </div>

                </div>
            </div >
            {/* <div className="footer_logo mt-2">
                <div>
                    <p className='mb-0'>4baseCare Onco Solutions Pvt. Ltd. All rights reserved. 2024-2025.</p>
                </div>
                <div className='note_img mr-1'>
                    <img src={Comapany} />
                </div>

            </div> */}
        </div >
    );
}

