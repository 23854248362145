import React, { createContext, useState } from 'react';
export const UserDatailContext = createContext();
export const LoaderContext = createContext();
export const SideMenuHideShowContext = createContext();

export const UserDetailProvider = (props) => {
    const [userDetails, setUserDetails] = useState('')
    return (
        <UserDatailContext.Provider value={[userDetails, setUserDetails]}>
            {props.children}
        </UserDatailContext.Provider>
    )
}

export const LoaderProvider = (props) => {
    const [IsLoading, setIsLoading] = useState(false)
    return (
        <LoaderContext.Provider value={[IsLoading, setIsLoading]}>
            {props.children}
        </LoaderContext.Provider>
    )
}

export const SideMuneHideShowProvider = (props) => {
    const [sideMeneShowHide, setSideMeneShowHide] = useState(true)
    return (
        <SideMenuHideShowContext.Provider value={[sideMeneShowHide, setSideMeneShowHide]}>
            {props.children}
        </SideMenuHideShowContext.Provider>
    )
}