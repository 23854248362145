import React, { useEffect, useContext, useState } from "react";
import HRR from "./Components/HRR";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { setDefaultHeader, apiCall } from "../../utils/httpClient";
import ApiEndPoint from "../../utils/apiEndPoints";
import { GLOBAL_URL } from '../../utils/constants'

import { AuthContext } from "../../Context/context";
import { LoaderContext, UserDatailContext } from "../../Context/userContext";

const HRRScreen = () => {
    const history = useNavigate()
    const { signInFun, signOutFun } = React.useContext(AuthContext);
    const [IsLoading, setIsLoading] = useContext(LoaderContext);
    
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [userDetails, setUserDetails] = useContext(UserDatailContext);
    const [allGraphData, setAllGraphData] = useState([])
    const [filterVal, setFilterVal] = useState('')
    const [pdlFilterVal, setPdlFilterVal] = useState('')
    const [pdlCloneFilterVal, setPdlCloneFilterVal] = useState('')
    const [top10totalCount, setTop10TotalCount] = useState(0)
    const [top20CitiesData, setTop20CitiesData] = useState([])
    const [hospitalNameData, setHospitalNameData] = useState([]);
    const [hospitalName, setHospitalName] = useState('');
    useEffect(() => {
        AllGraphsList()
    }, [])

    function apiResponse(data) {
        setAllGraphData(data.payLoad);
        const initialValue = 0;
        const topCities = data.payLoad?.hospitalCities?.filter((item) => { return item.value > 0 ? item : '' })
        setTop20CitiesData(topCities)
        const sumWithTop10Initial = data.payLoad?.pdl1List?.reduce(
            (accumulator, currentValue) => accumulator + currentValue.value,
            initialValue
        );
        const hospital = data?.payLoad?.hospitalCities[0].hospitalNameResList?.filter((item) => {
            if ((item.value !== 0)) {
                return item
            }
        })
        setHospitalNameData(hospital)
        setHospitalName(data?.payLoad?.hospitalCities[0].name)
        setTop10TotalCount(sumWithTop10Initial);
        setIsLoading(false)
    }

    const AllGraphsList = async () => {
        setIsLoading(true)
        const { data } = await apiCall('GET', ApiEndPoint.PDLAnalysis)
        if (data.success) {
            setFilterVal('')
            setPdlFilterVal('')
            setPdlCloneFilterVal('')
            apiResponse(data)
            setIsLoading(false)
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } else {
            setIsLoading(false)
            toast.error(data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const excelDownload = async () => {
        setDownloadLoading(true)
        const anchor = document.createElement('a');
        let headers = new Headers();
        const token = await localStorage.getItem('userData');
        headers.append('Authorization', `Bearer ${token}`);
        headers.append('Content-Type', 'application/json');
        // console.log('headers: ', headers);
        fetch(GLOBAL_URL + ApiEndPoint.pdl1ExportExcel, { headers })
            .then(response => response.blob())
            .then(data => {
                console.log('data: ', data);
                setDownloadLoading(false)
                if (data.type === "application/octet-stream") {
                    let objectUrl = window.URL.createObjectURL(data);
                    anchor.href = objectUrl;
                    anchor.download = 'PDL_Excel.xlsx';
                    anchor.click();
                    anchor.remove();
                    window.URL.revokeObjectURL(objectUrl);
                    toast.success('Successfully downloaded !', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    setDownloadLoading(false)
                    toast.error('Something went wrong', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            });
    }

    const filterGraphs = async (value, pdl, pdlClone) => {
        setIsLoading(true)
        setFilterVal(value)
        setPdlFilterVal(pdl)
        setPdlCloneFilterVal(pdlClone)
        const params = {
            dateFilter: value ? value : null,
            pdl1StatusFilter: pdl ? pdl : null,
            pdl1CloneFilter: pdlClone ? pdlClone : null
        }
        const { data } = await apiCall('POST', ApiEndPoint.PDLFilterGraphs, params)
        if (data.success) {
            apiResponse(data)
            setIsLoading(false)
        } else {
            setIsLoading(false)
            toast.error(data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }


    return (
        <>
            <HRR
                allGraphData={allGraphData}
                userDetails={userDetails}
                filterGraphs={(val, pdl, pdlClone) => filterGraphs(val, pdl, pdlClone)}
                AllGraphsList={() => AllGraphsList()}
                filterVal={filterVal}
                pdlCloneFilterVal={pdlCloneFilterVal}
                pdlFilterVal={pdlFilterVal}
                top10totalCount={top10totalCount}
                top20CitiesData={top20CitiesData}
                hospitalNameData={hospitalNameData}
                setHospitalNameData={setHospitalNameData}
                hospitalName={hospitalName}
                setHospitalName={setHospitalName}
                excelDownload={excelDownload}
                downloadLoading={downloadLoading}
            />
        </>
    )
}
export default HRRScreen