

import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { setDefaultHeader, apiCall } from "../../utils/httpClient";
import ApiEndPoint from "../../utils/apiEndPoints";
import { AuthContext } from "../../Context/context";
import { LoaderContext, UserDatailContext } from "../../Context/userContext";
import Home1 from "./Components/Home1";

const Home1Screen = () => {
    const history = useNavigate()
    const { signInFun, signOutFun } = React.useContext(AuthContext);
    const [IsLoading, setIsLoading] = useContext(LoaderContext);
    const [userDetails, setUserDetails] = useContext(UserDatailContext);
    const [homeData, setHomeData] = useState([]);
    const [totalpatients, setTotalPatients] = useState()
    const [clinicalVal, setClinicalVal] = useState([]);

    useEffect(() => {
        patientsFun()
        fetchfun()
        fetchclinicalfun()
    }, [])
    //  const params = {
    //     dateFilter:value ? value : null
    //   }
    //     const { data } = await apiCall('POST', ApiEndPoint.FilterGraphs, params)
    const patientsFun = async () => {
        const params = {
            dateFilter: null
        }
        setIsLoading(true);
        try {
            const { data } = await apiCall('POST', ApiEndPoint.DashboardTotalVal, params)
            setIsLoading(true);
            console.log("patientstotaldata", data);

            if (data) {
                setIsLoading(false);
                setTotalPatients(data?.payLoad)
            } else {
                setIsLoading(false);
                toast.error(data, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        } catch (error) {
            console.error("Error occurred", error);
            setIsLoading(false);
        }
    };
    const fetchfun = async () => {
        setIsLoading(true);
        try {
            const { data } = await apiCall('GET', ApiEndPoint.HomeData);
            setIsLoading(true);
            console.log("homedata", data);

            if (data) {
                setIsLoading(false);
                setHomeData(data?.payLoad)
            } else {
                setIsLoading(false);
                toast.error(data, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        } catch (error) {
            console.error("Error occurred", error);
            setIsLoading(false);
            // Handle error
        }
    };
    const fetchclinicalfun = async () => {
        const params = {
            dateFilter: null,
            pdl1StatusFilter: null,
            pdl1CloneFilter: null,
            cancerSiteFilter: null,
            //cancerSiteFilter:primaryFilterVal.length > 0 ? primaryFilterVal.map((x) => x.value) : null,
            drugFilter: null,
            biologicalDrugFilter: null,
        };
        setIsLoading(true);
        try {
            // apiCall('POST', ApiEndPoint.PdlTotalPatients, params),

            const { data } = await apiCall('POST', ApiEndPoint.PdlTotalPatients, params);
            setIsLoading(true);
            console.log("clinicaldata", data);

            if (data) {
                setIsLoading(false);
                setClinicalVal(data?.payLoad)
            } else {
                setIsLoading(false);
                toast.error(data, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        } catch (error) {
            console.error("Error occurred", error);
            setIsLoading(false);
            // Handle error
        }
    };
    return (
        <>
            <Home1
                homeData={homeData}
                totalpatients={totalpatients}
                IsLoading={IsLoading}
                clinicalVal={clinicalVal}
            />
        </>

    )
}
export default Home1Screen
