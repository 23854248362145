import React, { useState, useContext } from "react";
import Registration from "./Components/Registration";
import { useNavigate } from 'react-router-dom';
import { apiCall } from "../../utils/httpClient";
import ApiEndPoint from "../../utils/apiEndPoints";
import { LoaderContext } from "../../Context/userContext";
import { toast } from "react-toastify";

const RegistrationScreen = () => {
    const history = useNavigate()
    const [, setIsLoading] = useContext(LoaderContext);
    const [registrationDetails, setRegistrationDetails] = useState({
        email: '',
        name: '',
        phoneNO: '',
        role: '',
        organization: '',
        reasonToJoin: ''
    })
    const [validError, setValidError] = useState(false)
    const [validEmail, setValidEmail] = useState(false)
    const [validPhoneNo,] = useState(false)
    const [passwordShow, setPasswordShow] = useState(true)
    const [showThankueMsg, setShowThankueMsg] = useState(false)
    const [buttonShow, setButtonShow] = useState({
        email: false,
        name: false,
        phoneNO: false,
        role: false,
        organization: false,
        reasonToJoin: false
    })
    const handleChangeEmail = (event) => {
        setRegistrationDetails({
            ...registrationDetails,
            email: event.target.value
        })
        setValidEmail(false)
        if (event.target.value) {
            setButtonShow({
                ...buttonShow,
                email: true
            })
        } else {
            setButtonShow({
                ...buttonShow,
                email: false
            })
        }
    };
    const handleChangePassword = (event) => {
        setRegistrationDetails({
            ...registrationDetails,
            password: event.target.value
        })
        if (event.target.value) {
            setButtonShow({
                ...buttonShow,
                password: true
            })
        } else {
            setButtonShow({
                ...buttonShow,
                password: false
            })
        }
    };

    const handleChangeName = (event) => {
        setRegistrationDetails({
            ...registrationDetails,
            name: event.target.value
        })
        if (event.target.value) {
            setButtonShow({
                ...buttonShow,
                name: true
            })
        } else {
            setButtonShow({
                ...buttonShow,
                name: false
            })
        }
    };

    const handleChangePhoneNO = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === "" || re.test(event.target.value)) {
            setRegistrationDetails({
                ...registrationDetails,
                phoneNO: event.target.value
            })
        }
        if (event.target.value) {
            setButtonShow({
                ...buttonShow,
                phoneNO: true
            })
        } else {
            setButtonShow({
                ...buttonShow,
                phoneNO: false
            })
        }
    };

    const handleChangeRole = (event) => {
        setRegistrationDetails({
            ...registrationDetails,
            role: event.target.value
        })
        if (event.target.value) {
            setButtonShow({
                ...buttonShow,
                role: true
            })
        } else {
            setButtonShow({
                ...buttonShow,
                role: false
            })
        }
    };

    const handleChangeOrganization = (event) => {
        setRegistrationDetails({
            ...registrationDetails,
            organization: event.target.value
        })
        if (event.target.value) {
            setButtonShow({
                ...buttonShow,
                organization: true
            })
        } else {
            setButtonShow({
                ...buttonShow,
                organization: false
            })
        }
    };

    const handleChangeReasonToJoin = (event) => {
        setRegistrationDetails({
            ...registrationDetails,
            reasonToJoin: event.target.value
        })
        if (event.target.value) {
            setButtonShow({
                ...buttonShow,
                reasonToJoin: true
            })
        } else {
            setButtonShow({
                ...buttonShow,
                reasonToJoin: false
            })
        }
    };

    const validatoin = () => {
        if (!registrationDetails.name.length > 0) {
            setValidError(true)
            return false;
        }
        if (!registrationDetails.email.length > 0) {
            setValidError(true)
            return false;
        }
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!registrationDetails.email.match(validRegex)) {
            setValidEmail(true)
            return false;
        } else {
            setValidEmail(false)
        }
        if (!registrationDetails.phoneNO.length > 0) {
            setValidError(true)
            return false;
        }
        if (!registrationDetails.role.length > 0) {
            setValidError(true)
            return false;
        }
        if (!registrationDetails.organization.length > 0) {
            setValidError(true)
            return false;
        }
        if (!registrationDetails.reasonToJoin.length > 0) {
            setValidError(true)
            return false;
        }
        // if (!registrationDetails.password.length > 0) {
        //     setValidError(true)
        //     return false;
        // }
        setValidError(false)
        return true
    }

    const registrationFun = async () => {
        const valid = validatoin()
        if (valid) {
            const params = {
                email: registrationDetails.email,
                name: registrationDetails.name,
                phoneNO: registrationDetails.phoneNO,
                role: registrationDetails.role,
                organization: registrationDetails.organization,
                reasonToJoin: registrationDetails.reasonToJoin,
            };
            setIsLoading(true)
            const { data } = await apiCall('POST', ApiEndPoint.LOGIN_REQUEST, params)
            if (data.success) {
                setShowThankueMsg(true)
                setIsLoading(false)
                history('/thankuPage')
            } else {
                setIsLoading(false)
                toast.error(data?.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    function passwordShowFun() {
        setPasswordShow(!passwordShow)
    }

    function navToLogin() {
        history('/')
    }
    return (
        <>
            <Registration
                handleChangeEmail={(e) => handleChangeEmail(e)}
                handleChangeName={(e) => handleChangeName(e)}
                handleChangePhoneNO={(e) => handleChangePhoneNO(e)}
                handleChangeRole={(e) => handleChangeRole(e)}
                handleChangeOrganization={(e) => handleChangeOrganization(e)}
                handleChangeReasonToJoin={(e) => handleChangeReasonToJoin(e)}
                handleChangePassword={(e) => handleChangePassword(e)}
                passwordShowFun={() => passwordShowFun()}
                passwordShow={passwordShow}
                registrationDetails={registrationDetails}
                registrationFun={registrationFun}
                validError={validError}
                showThankueMsg={showThankueMsg}
                validEmail={validEmail}
                validPhoneNo={validPhoneNo}
                buttonShow={buttonShow}
                navToLogin={navToLogin}
            />
        </>
    )
}
export default RegistrationScreen   