import React, { useEffect, useContext, useState } from "react";
import EGFRAnyalsis from "./Components/EGFRAnyalsis";
import { toast } from "react-toastify";
import { apiCall } from "../../utils/httpClient";
import ApiEndPoint from "../../utils/apiEndPoints";
import { GLOBAL_URL } from '../../utils/constants'

//import { AuthContext } from "../../Context/context";
import { LoaderContext, UserDatailContext } from "../../Context/userContext";

const EGFRAnyalsisScreen = () => {
    //  const { signInFun, signOutFun } = React.useContext(AuthContext);
    const [, setIsLoading] = useContext(LoaderContext);

    const [downloadLoading, setDownloadLoading] = useState(false);
    const [userDetails,] = useContext(UserDatailContext);
    const [allGraphData, setAllGraphData] = useState([])
    const [filterVal, setFilterVal] = useState('')
    const [AlkVariationFilter, setAlkVariationFilter] = useState('')
    const [alkDetecionStatus, setAlkDetecionStatus] = useState('')
    const [primarySiteTotal, setPrimarySiteTotal] = useState(0)
    const [alkVariationTotal, setAlkVariationTotal] = useState(0)
    const [top20CitiesData, setTop20CitiesData] = useState([])
    const [hospitalNameData, setHospitalNameData] = useState([]);
    const [hospitalName, setHospitalName] = useState('');
    const [alkStatus, setAlkStatus] = useState();
    const [primarySiteDistribution, setPrimarySiteDistribution] = useState([]);
    const [alkVariations, setAlkVariation] = useState([]);
    const [alkTotals, setAlkTotals] = useState([]);
    const [cancerType, setCancerType] = useState([]);
    const [cancerTypeList, setCancerTypeList] = useState([]);
    const [hospitalList, setHospitalList] = useState([]);
    const [cancerTypeName, setCancerTypeName] = useState('');

    useEffect(() => {
        AllCancerTypeList()
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])



    const AllCancerTypeList = async () => {
        setIsLoading(true)
        const { data } = await apiCall('GET', ApiEndPoint.cancerTypeDropdown)
        if (data.success) {
            setCancerTypeList(data.payLoad)
            setCancerType(data.payLoad)
            setIsLoading(false)
            AllGraphsList()
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } else {
            setIsLoading(false)
            AllGraphsList()
            toast.error(data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
    const searchCancerType = (event) => {
        const searchTerm = event.target.value;
        const updatedList = cancerTypeList.filter((item) => {
            return item?.name?.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
        });
        setCancerType(updatedList)
    }

    function apiResponse(data) {
        setAllGraphData(data.payLoad);
        const initialValue = 0;
        const topCities = data.payLoad?.hospitalCities?.filter((item) => { return item.value > 0 ? item : '' })
        setTop20CitiesData(topCities)
        // const sumWithTop10Initial = data.payLoad?.pdl1List?.reduce(
        //     (accumulator, currentValue) => accumulator + currentValue.value,
        //     initialValue
        // );
        // const hospital = data?.payLoad?.hospitalCities[0].hospitalNameResList?.filter((item) => {
        //     if ((item.value != 0)) {
        //         return item
        //     }
        // })
        const primarySite = data?.payLoad?.cancerSiteDistributionList
            ?.filter(item => item.value !== 0)
            .sort((a, b) => b.value - a.value)
            .filter(item => item.value >= 13);

        const primarySiteTotal = primarySite.reduce(
            (accumulator, currentValue) => accumulator + currentValue.value,
            initialValue
        );

        const alkVari = data?.payLoad?.geneNameVariation
            ?.filter(item => item.value !== 0)
            .sort((a, b) => b.value - a.value)
            .filter(item => item.value >= 1);

        const alkStatus = data?.payLoad?.geneNameStatus
            ?.filter(item => item.value !== 0);


        const alkTotal = alkStatus?.reduce(
            (accumulator, currentValue) => accumulator + currentValue.value,
            initialValue
        );

        const alkVariTotal = alkVari?.reduce(
            (accumulator, currentValue) => accumulator + currentValue.value,
            initialValue
        );
        const hospitalNames = data?.payLoad?.hospitalNames?.filter(item =>
            item.hospitalCity === data?.payLoad?.hospitalCities[0]?.name
        );

        const transformedArray = [];
        hospitalNames?.map((item) => {
            return (
                Object.entries(item).forEach(([name, value]) => {
                    transformedArray.push({ name, value });
                })
            )
        })
        const finalHospital = transformedArray?.filter((item) => item.value !== data?.payLoad?.hospitalCities[0]?.name && item)


        setPrimarySiteTotal(primarySiteTotal)
        setPrimarySiteDistribution(primarySite)


        setAlkVariation(alkVari)
        setAlkVariationTotal(alkVariTotal)
        setHospitalNameData(data?.payLoad?.hospitalNames)
        setAlkStatus(alkStatus)
        setAlkTotals(alkTotal)
        setHospitalName(data?.payLoad?.hospitalCities[0]?.name)
        setHospitalList(finalHospital)
        // setTop10TotalCount(sumWithTop10Initial);
        setIsLoading(false)
    }


    const AllGraphsList = async () => {
        setIsLoading(true)
        // const { data } = await apiCall('GET', ApiEndPoint.PDLAnalysis)
        const { data } = await apiCall('GET', ApiEndPoint.EGFRRocheGeneAnalysis)
        if (data.success) {
            setFilterVal('')
            setAlkVariationFilter('')
            setAlkDetecionStatus('')
            setCancerTypeName('')
            setIsLoading(false)
            apiResponse(data)
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } else {
            setIsLoading(false)
            toast.error(data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const excelDownload = async () => {
        setDownloadLoading(true)
        const anchor = document.createElement('a');
        let headers = new Headers();
        const token = await localStorage.getItem('userData');
        headers.append('Authorization', `Bearer ${token}`);
        headers.append('Content-Type', 'application/json');
        // console.log('headers: ', headers);
        fetch(GLOBAL_URL + ApiEndPoint.pdl1ExportExcel, { headers })
            .then(response => response.blob())
            .then(data => {
                console.log('data: ', data);
                setDownloadLoading(false)
                if (data.type === "application/octet-stream") {
                    let objectUrl = window.URL.createObjectURL(data);
                    anchor.href = objectUrl;
                    anchor.download = 'PDL_Excel.xlsx';
                    anchor.click();
                    anchor.remove();
                    window.URL.revokeObjectURL(objectUrl);
                    toast.success('Successfully downloaded !', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    setDownloadLoading(false)
                    toast.error('Something went wrong', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            });
    }

    const filterGraphs = async (value, Variation, status, canType) => {
        setIsLoading(true)
        setFilterVal(value)
        setAlkVariationFilter(Variation)
        setAlkDetecionStatus(status)
        setCancerTypeName(canType)
        const params = {
            dateFilter: value ? value : null,
            geneVariationType: Variation ? Variation : null,
            geneStatusType: status ? status : null,
            cancerSite: canType ? canType : null
        }
        const { data } = await apiCall('POST', ApiEndPoint.EGFRFilter, params)
        if (data.success) {
            setIsLoading(false)
            apiResponse(data)
        } else {
            setIsLoading(false)
            toast.error(data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        setIsLoading(false)
    }


    return (
        <>
            <EGFRAnyalsis
                allGraphData={allGraphData}
                userDetails={userDetails}
                filterGraphs={(val, pdl, pdlClone, canType) => filterGraphs(val, pdl, pdlClone, canType)}
                AllGraphsList={() => AllGraphsList()}
                filterVal={filterVal}
                alkDetecionStatus={alkDetecionStatus}
                AlkVariationFilter={AlkVariationFilter}
                top20CitiesData={top20CitiesData}
                hospitalNameData={hospitalNameData}
                setHospitalNameData={setHospitalNameData}
                hospitalName={hospitalName}
                setHospitalName={setHospitalName}
                excelDownload={excelDownload}
                primarySiteDistribution={primarySiteDistribution}
                primarySiteTotal={primarySiteTotal}
                downloadLoading={downloadLoading}
                alkStatus={alkStatus}
                alkVariationTotal={alkVariationTotal}
                alkVariations={alkVariations}
                alkTotals={alkTotals}
                cancerType={cancerType}
                cancerTypeName={cancerTypeName}
                searchCancerType={searchCancerType}
                hospitalList={hospitalList}
                setHospitalList={setHospitalList}
            />
        </>
    )
}
export default EGFRAnyalsisScreen