import React from 'react';
//import { Routes, Route } from "react-router-dom";
import MainRoute from './Route';
import './App.css'
import {
  LoaderProvider, SideMuneHideShowProvider, UserDetailProvider
} from "./Context/userContext";
import { ToastContainer } from 'react-toastify';
//import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <div>
      <LoaderProvider>
        <SideMuneHideShowProvider>
          <UserDetailProvider>
            <MainRoute />
            <ToastContainer />
          </UserDetailProvider>
        </SideMuneHideShowProvider>
      </LoaderProvider>
    </div>
  )
}
export default App;
