import React from 'react';
import BrandLogo from "../../../assets/images/main_logo.svg";
import comapanyLogo from "../../../assets/images/4bc.svg";
import slider1 from '../../../assets/images/slider1.svg';
import slider2 from '../../../assets/images/6000_cancer.svg';
import slider3 from '../../../assets/images/Map_location.svg';

import "./style.css"

export default function Login(props) {
    const { handleChangeName, handleChangeEmail, handleChangePhoneNO,
        handleChangeRole, handleChangeOrganization, handleChangeReasonToJoin,
        registrationFun, navToLogin, registrationDetails, buttonShow, validError, validEmail } = props

    return (
        <div className="row login_wrapper cust_align mx-0">
            <div className="left_logo">
                <img src={BrandLogo} alt="" />
            </div>
            <div className="right_logo">
                <img src={comapanyLogo} className="w-85" alt="" />
            </div>
            <div className="col-12 col-lg-6 col-md-12 col-sm-12 left_panel">
                <div className="dna_img">
                    <img src="../../../assets/images/dna_image.png" className="img-fluid" alt="" />
                </div>
                <div className="logo text-right d-none">
                    <img src="../../../../assets/images/login_logo.png" className="img-fluid w-75" alt="Logo" />
                </div>
                <div id="demo" className="carousel slide" data-ride="carousel">
                    <ul className="carousel-indicators">
                        <li data-target="#demo" data-slide-to="0" className="active"></li>
                        <li data-target="#demo" data-slide-to="1"></li>
                        <li data-target="#demo" data-slide-to="2"></li>
                    </ul>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src={slider1} alt="Doctor-image" className="img-fluid w-40 m-auto " />
                            <div className="min_height py-3">
                                <p >A growing dataset of unique mutations across Asia </p>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src={slider2} alt="Doctor-image" className="img-fluid w-40 m-auto " />
                            <div className="min_height py-3">
                                <p >Continuously evolving database with 300+ new cases added every month </p>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src={slider3} alt="Doctor-image" className="img-fluid w-40 m-auto " />
                            <div className="min_height py-3">
                                <p>Working with leading healthcare and diagnostic chains across India & other parts of Asia  </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-5 col-md-12 col-sm-12 right_panel px-0">
                <div className="form_section p-5 mt-5">
                    <div className="text-center">
                        <h3 className="text-blue">Create your account</h3>
                        <p className="w-59 m-auto font-weight-normal">We will need your details to give you a better experience with <span onClick={() => navToLogin()} className="text-soft_blue">OncoBiz</span></p>
                    </div>
                    <form className=" mt-4 pt-3  pr-2 " >
                        <div className="row mb-2">
                            <div className="col">
                                <label>Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    placeholder="Enter Name"
                                    onChange={(e) => handleChangeName(e)}
                                    value={registrationDetails.name}
                                />
                                {validError && !registrationDetails.name.length > 0 && <span className='label_error'>Please Enter Name </span>}
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col">
                                <label>Email Id</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter email"
                                    onChange={(e) => handleChangeEmail(e)}
                                    value={registrationDetails.email}
                                />
                                {validError && !registrationDetails.email.length > 0 && <span className='label_error'>Please Enter Email </span>}
                                {validEmail && <span className='label_error'>Please Enter Valid Email </span>}
                            </div>
                            <div className="col">
                                <label> Phone number</label>
                                <input
                                    type="tel"
                                    className="form-control"
                                    placeholder="+91 Enter phone"
                                    onChange={(e) => handleChangePhoneNO(e)}
                                    value={registrationDetails.phoneNO}
                                    maxLength='10'
                                    pattern="^[0-9]*[.,]?[0-9]*$"

                                />
                                {validError && !registrationDetails.phoneNO.length > 0 && <span className='label_error'>Please Enter Phone Number </span>}
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col">
                                <label>Role</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your role"
                                    onChange={(e) => handleChangeRole(e)}
                                    value={registrationDetails.role}
                                />
                                {validError && !registrationDetails.role.length > 0 && <span className='label_error'>Please Enter Role </span>}
                            </div>
                            <div className="col">
                                <label>Organization</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your Organization"
                                    onChange={(e) => handleChangeOrganization(e)}
                                    value={registrationDetails.organization}
                                />
                                {validError && !registrationDetails.organization.length > 0 && <span className='label_error'>Please Enter Organization </span>}
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col">
                                <label>Reason to join</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter reason"
                                    onChange={(e) => handleChangeReasonToJoin(e)}
                                    value={registrationDetails.reasonToJoin}
                                />
                                {validError && !registrationDetails.reasonToJoin.length > 0 && <span className='label_error'>Please Enter Reason </span>}
                            </div>
                        </div>
                        <div className="cust_btn mt-4 pt-3">
                            <button type='button'
                                className="btn btn-secondary"
                                style={{ backgroundColor: Object.values(buttonShow)?.every(Boolean) ? '#5373ff' : '' }}
                                onClick={() => registrationFun()}>Send Request</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}