//import React, { useEffect, useState } from 'react';
import BrandLogo from "../../../assets/images/main_logo.svg";
import comapanyLogo from "../../../assets/images/4bc.svg";
import slider1 from '../../../assets/images/slider1.svg';
import slider2 from '../../../assets/images/6000_cancer.svg';
import slider3 from '../../../assets/images/Map_location.svg';
import successMark from '../../../assets/images/success_tick_mark.png'

import "./style.css"

export default function ThankuPage(props) {
   return (

      <div className="row login_wrapper cust_align mx-0">
         <div className="left_logo">
            <img src={BrandLogo} alt="Logo" />
         </div>
         <div className="right_logo">
            <img src={comapanyLogo} className="w-85" alt="Logo" />
         </div>
         <div className="col-12 col-lg-6 col-md-12 col-sm-12 left_panel">
            <div className="dna_img">
               <img src="../../../assets/images/dna_image.png" className="img-fluid" alt="" />
            </div>
            <div className="logo text-right d-none">
               <img src="../../../../assets/images/login_logo.png" className="img-fluid w-75" alt="Logo" />
            </div>
            <div id="demo" className="carousel slide" data-ride="carousel">
               <ul className="carousel-indicators">
                  <li data-target="#demo" data-slide-to="0" className="active"></li>
                  <li data-target="#demo" data-slide-to="1"></li>
                  <li data-target="#demo" data-slide-to="2"></li>
               </ul>
               <div className="carousel-inner">
                  <div className="carousel-item active">
                     <img src={slider1} alt="Doctor-image" className="img-fluid w-40 m-auto " />
                     <div className="min_height py-3">
                        <p >A growing dataset of unique mutations across Asia </p>
                     </div>
                  </div>
                  <div className="carousel-item">
                     <img src={slider2} alt="Doctor-image" className="img-fluid w-40 m-auto " />
                     <div className="min_height py-3">
                        <p >Continuously evolving database with 300+ new cases added every month</p>
                     </div>
                  </div>
                  <div className="carousel-item">
                     <img src={slider3} alt="Doctor-image" className="img-fluid w-40 m-auto " />
                     <div className="min_height py-3">
                        <p>Working with leading healthcare and diagnostic chains across India & other parts of Asia  </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="col-12 col-lg-5 col-md-12 col-sm-12 right_panel px-0">
            <div className="form_section p-5 mt-lg-5 mt-md-5 success_compo">
               <div className="text-center w-100">
                  <div className="text-center">
                     <img src={successMark} className="w-75" alt=" " />
                  </div>
                  <h6 className="w-60 m-auto ">Thank you for your interest.</h6>
                  <h6 className="w-60 m-auto ">Our Team is reviewing your details.</h6>
                  <h6 className="w-60 m-auto ">Please check your <a href="#">email</a> for more information</h6>
               </div>

            </div>
         </div>
      </div>
   )
}