import React, { useEffect, useState } from 'react';
import BrandLogo from "../../../assets/images/main_logo.svg";
import comapanyLogo from "../../../assets/images/4bc.svg";
import slider1 from '../../../assets/images/slider1.svg';
import slider2 from '../../../assets/images/6000_cancer.svg';
import slider3 from '../../../assets/images/Map_location.svg';

import "./style.css"

export default function ForgotPassword(props) {
   const { handleChangeEmail, handleChangePassword, handleConfirmPassword, navToLogin, password,
      sendEmailFun, showResetPassword, email, confirmPassword, resetPaawordFun, validError, validEmail, validPassAndConfirm } = props
   return (
      <>
         {!showResetPassword ?
            <div className="row login_wrapper cust_align mx-0">
               <div className="left_logo">
                  <img src={BrandLogo} />
               </div>
               <div className="right_logo">
                  <img src={comapanyLogo} className="w-85" />
               </div>
               <div className="col-12 col-lg-6 col-md-12 col-sm-12 left_panel">
                  <div className="dna_img">
                     <img src="../../../assets/images/dna_image.png" className="img-fluid" alt="" />
                  </div>
                  <div className="logo text-right d-none">
                     <img src="../../../../assets/images/login_logo.png" className="img-fluid w-75" alt="Logo" />
                  </div>
                  <div id="demo" className="carousel slide" data-ride="carousel">
                     <ul className="carousel-indicators">
                        <li data-target="#demo" data-slide-to="0" className="active"></li>
                        <li data-target="#demo" data-slide-to="1"></li>
                        <li data-target="#demo" data-slide-to="2"></li>
                     </ul>
                     <div className="carousel-inner">
                        <div className="carousel-item active">
                           <img src={slider1} alt="Doctor-image" className="img-fluid w-50 m-auto " />
                           <div className="min_height py-3">
                              <p >A growing dataset of unique mutations across Asia </p>
                           </div>
                        </div>
                        <div className="carousel-item">
                           <img src={slider2} alt="Doctor-image" className="img-fluid w-50 m-auto " />
                           <div className="min_height py-3">
                              <p >Continuously evolving database with 300+ new cases added every month</p>
                           </div>
                        </div>
                        <div className="carousel-item">
                           <img src={slider3} alt="Doctor-image" className="img-fluid w-50 m-auto " />
                           <div className="min_height py-3">
                              <p>Working with leading healthcare and diagnostic chains across India & other parts of Asia </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-12 col-lg-5 col-md-12 col-sm-12 right_panel px-0">
                  <div className="form_section p-5 mt-5">
                     <div className="text-center">
                        <h3 className="text-blue">Forgot Password ..?</h3>
                     </div>
                     <form className="pb-5 mt-4 pt-3  pr-2 " >
                        <div className="row mb-3 ">
                           <label htmlFor="email" >Email </label>
                           <input type="email"
                              className="form-control"
                              placeholder="Enter your email Id"
                              onChange={(e) => handleChangeEmail(e)}
                              value={email}
                           />
                           {validError && !email.length > 0 && <span className='label_error'>Please Enter Email </span>}
                           {validEmail && <span className='label_error'>Please Enter Valid Email </span>}

                        </div>
                        <div className="cust_btn mt-5 pt-3">
                           <button type='button'
                              className="btn btn-info login_btn-color"
                              onClick={() => sendEmailFun()}
                           >Send Email</button>
                        </div>
                        <div className="login_footer mt-2 text-center">
                           <p className="font-weight-light"> <a onClick={() => navToLogin()} className="text-soft_blue font-weight-normal">Back to Login </a>  </p>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
            :
            <div className="row login_wrapper cust_align mx-0">
               <div className="left_logo">
                  <img src={BrandLogo} />
               </div>
               <div className="right_logo">
                  <img src={comapanyLogo} className="w-85" />
               </div>
               <div className="col-12 col-lg-6 col-md-12 col-sm-12 left_panel">
                  <div className="dna_img">
                     <img src="../../../assets/images/dna_image.png" className="img-fluid" alt="" />
                  </div>
                  <div className="logo text-right d-none">
                     <img src="../../../../assets/images/login_logo.png" className="img-fluid w-75" alt="Logo" />
                  </div>
                  <div id="demo" className="carousel slide" data-ride="carousel">
                     <ul className="carousel-indicators">
                        <li data-target="#demo" data-slide-to="0" className="active"></li>
                        <li data-target="#demo" data-slide-to="1"></li>
                        <li data-target="#demo" data-slide-to="2"></li>
                     </ul>
                     <div className="carousel-inner">
                        <div className="carousel-item active">
                           <img src={slider1} alt="Doctor-image" className="img-fluid w-50 m-auto " />
                           <div className="min_height py-3">
                              <p >A growing dataset of unique mutations across Asia </p>
                           </div>
                        </div>
                        <div className="carousel-item">
                           <img src={slider2} alt="Doctor-image" className="img-fluid w-50 m-auto " />
                           <div className="min_height py-3">
                              <p >Continuously evolving database with 300+ new cases added every month </p>
                           </div>
                        </div>
                        <div className="carousel-item">
                           <img src={slider3} alt="Doctor-image" className="img-fluid w-50 m-auto " />
                           <div className="min_height py-3">
                              <p>Working with leading healthcare and diagnostic chains across India & other parts of Asia  </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-12 col-lg-5 col-md-12 col-sm-12 right_panel px-0">
                  <div className="form_section p-5 mt-5">
                     <div className="text-center">
                        <h3 className="text-blue">Reset Password</h3>
                     </div>
                     <form className=" mt-4 pt-3  pr-2 " >
                        <div className="row mb-3 ">
                           <label htmlFor="email" >your Email </label>
                           <input type="email"
                              className="form-control"
                              placeholder="Enter your email Id"
                              onChange={(e) => handleChangeEmail(e)}
                              value={email}
                           />
                           {validError && !email.length > 0 && <span className='label_error'>Please Enter Email </span>}
                           {validEmail && <span className='label_error'>Please Enter Valid Email </span>}
                        </div>
                        <div className="row mb-3 ">
                           <label htmlFor="email" >New Password </label>
                           <input type="password"
                              className="form-control"
                              placeholder="Enter your email Id"
                              onChange={(e) => handleChangePassword(e)}
                              password={password}
                           />
                           {validError && !password.length > 0 && <span className='label_error'>Please Enter Password </span>}
                        </div>
                        <div className="row mb-3 ">
                           <label htmlFor="email" >Confirm Password </label>
                           <input type="password"
                              className="form-control"
                              placeholder="Enter your email Id"
                              onChange={(e) => handleConfirmPassword(e)}
                              value={confirmPassword}
                           />
                           {validError && !confirmPassword.length > 0 && <span className='label_error'>Please Enter Confirm Password </span>}
                           {validPassAndConfirm && <span className='label_error'>Password And Confirm Password Not Match</span>}

                        </div>
                        <div className="cust_btn mt-3 pt-3">
                           <button type='button'
                              className="btn btn-info login_btn-color"
                              onClick={() => resetPaawordFun()}
                           >Send Email</button>
                        </div>
                        <div className="login_footer mt-2 text-center">
                           <p className="font-weight-light"> <a onClick={() => navToLogin()} className="text-soft_blue font-weight-normal">Back to Login </a>  </p>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         }
      </>
   )
}