// export const GLOBAL_URL = 'http://localhost:8085'
// export const GLOBAL_URL = 'http://52.66.98.251:9511' 
export const GLOBAL_URL = 'https://www.v2.api.ecrf.4basecare.co.in'

export const ColorValues=[
    {
        code: '#008000',
        name: 'Low'
    }, {
        code: '#ff0000',
        name: 'High'
    },
    {
        code: '#008000',
        name: 'Stable'
    },
    {
        code: '#ff0000',
        name: 'Unstable'
    },
    {
        code: '#008000',
        name: 'Positive'
    }, 
    {
        code: '#ff0000',
        name: 'Negative'
    },
    {
        code: '#0000ff',
        name: 'Male'
    }, 
    {
        code: '#ff1493',
        name: 'Female'
    },
    
]
export const MSIColorValues=[
    {
        code: '#A8E6A3',
        name: 'Low'
    }, {
        code: '#DC3545',
        name: 'High'
    },
    {
        code: '#28A745',
        name: 'Stable'
    },
    {
        code: '#8B0000',
        name: 'Unstable'
    },
    {
        code: '#FFC107',
        name: 'Borderline'
    },
    {
        code: '#008000',
        name: 'Positive'
    }, 
    {
        code: '#ff0000',
        name: 'Negative'
    },
    {
        code: '#0000ff',
        name: 'Male'
    }, 
    {
        code: '#ff1493',
        name: 'Female'
    },
    
]
export const CanSiteColors = [
    {
      code: '#ff69b4',
      name: 'Breast'
    },
    {
      code: '#c46210',
      name: 'Kidney'
    },
    {
      code: '#32cd32',
      name: 'Gallbladder'
    },
    {
      code: '#008000',
      name: 'Liver and intrahepatic bile ducts'
    },
    {
      code: '#6495ed',
      name: 'Thyroid'
    },
    {
      code: '#1e90ff',
      name: 'Prostate'
    },
    {
      code: '#4169e1',
      name: 'Stomach'
    },
    {
      code: '#9400d3',
      name: 'Pancreas'
    },
    
    {
      code: '#a9a9a9',
      name: 'Lung'
    },
    {
      code: '#696969',
      name: 'Brain'
    },
    {
      code: '#4D4545',
      name: 'Skin'
    },
    {
      code: '#006994',
      name: 'Ovary'
    },
    {
      code: '#32cd32',
      name: 'Lymphnode'
    },
    {
      code: '#ffdab9',
      name: 'Uterus'
    },
    {
    code:'#0000ff',
    name: 'Esophagus'
    },
    {
    code: '#71F170',
    name: 'Large Intestine'
    }, {
    code: '#FF8B8B',
    name: 'Corpus Uteri'
    },
    ]
export const CancerSiteColors = [
    {
        code: '#71F170',
        name: 'Large Intestine'
    }, {
        code: '#B6B9B8',
        name: 'Brain'
    },
    {
        code: '#F06B9B',
        name: 'Breast'
    },
    {
        code: '#F47338',
        name: 'Kidney'
    },
    {
        code: '#F47338',
        name: 'Endometrial'
    },
    {
        code: '#F9A11A',
        name: 'Appendix'
    }, {
        code: '#EDA53D',
        name: 'Childhood'
    }, {
        code: '#F6E760',
        name: 'Bone'
    }, {
        code: '#7AC243',
        name: 'Non-Hodgkin lymphoma'
    },
    {
        code: '#01956D',
        name: 'Liver and intrahepatic bile ducts'
    }, {
        code: '#00A8B5',
        name: 'Ovary'
    }, {
        code: '#00A6B0',
        name: 'Cervical'
    }, {
        code: '#359DD7',
        name: 'Colon'
    }, {
        code: '#359DD7',
        name: 'Stomach'
    }, {
        code: '#C2B1D6',
        name: 'Testicular'
    }, {
        code: '#9C57A3',
        name: 'Pancreatic'
    }, {
        code: '#775CA1',
        name: 'All Cancers'
    }, {
        code: '#6A5EA8',
        name: 'Hodgkin lymphoma'
    }, {
        code: '#000000',
        name: 'Skin'
    },
    {
        code: '#F69C60',
        name: 'Lymphnode'
    },
    {
        code: '#BAF660',
        name: 'Small Intestine'
    },
    {
        code: '#EB455F',
        name: 'Cervix Uteri'
    },
    {
        code: '#FF8B8B',
        name: 'Corpus Uteri'
    },
    {
        code: '#00A8B5',
        name: 'Gallbladder'
    },
    {
        code: '#00D7E8',
        name: 'Esophagus'
    },
    {
        code: '#629F33',
        name: 'Pancreas'
    },
    {
        code: '#2B3467',
        name: 'Prostate'
    },
    {
        code: '#005A8D',
        name: 'Lung'
    },
    {
        code: '#F473B9',
        name: 'Rectosigmoid junction'
    },
    {
        code: '#2FA4FF',
        name: 'Parotid gland'
    },


]
export const colrs=['#c30b4e','#722f37','#efcc00','#967117','#008b8b','#367588','#f94d00','#cd853f','#8b4513','#8b0000','#ab4e52','#dc143c','#ff0000','#009698','#ffa500','#002147',]
export const HospitalColor = ['#5373FF', '#425F5D', '#780000', '#3B4856', '#5F4B8B', '#669BBC', '#006D77', '#83C5BE', '#008178', '#034078', '#FF7D00', '#57205F', '#0D3B66', '#7AC243', '#B5ECFE', '#FF8FCF', '#0096AA']

export const COLORS = ["#775CA1", "#022E57", "#FFB300", "#BAD7E9", "#ABC9FF", "#F9A11A", "#FF8B8B", '#EB4747', '#E8FFC2', '#00FFDD', '#2FA4FF',
    '#FFDDEE', '#F473B9', '#0E3EDA', '#DFF6FF', '#5D8BF4', '#276678', '#E2F91A', '#F9311A', '#F69C60', '#F17071', '#7071F1', '#35D7C0', '#35D7C0', '#35D7C0', '#7AC243', '#00D7E8', '#005A8D'];

export const tmbColor = ['#EB455F', '#323D7B', '#BAD7E9']

